import React, { useState } from "react";

//API
import { sendEmailResetPasswordApi } from "../../../api/user";

//Matrial UI
import { CircularProgress} from '@material-ui/core';

//Components
import  {InputWithLabel} from "../../molecules/InputWith/InputWithLabel";
import { Button } from "../../atoms/Button";
import AlertIcon from "../../../assets/img/alert.svg";
import Modal from "@material-ui/core/Modal";

export default function FormRecoverAccount(){
    const [isOpen, setIsOpen] = useState(false);
    const [modalBody, setModalBody] = useState('');

    function handleDeleteButton() {
        setIsOpen(true);
        setModalBody(
          <>
            <div className='d-flex justify-content-center mb-3'>
              <img
                src={AlertIcon}
                alt='Warning'
              />
            </div>
            <p className='mb-4 a-text-medium-second-neutral-90 text-center'>
                El correo electrónico no está asociado a ninguna cuenta
            </p>
            <div className='d-flex justify-content-center'>
              <div
                className='w-100'
                onClick={() => setIsOpen(false)}
              >
                <Button
                  type='button'
                  classes='a-button-primary1'
                  height={36}
                  width='100%'
                  text='Aceptar'
                />
              </div>
            </div>
          </>,
        );
    }

    const [ isLoading, setIsLoading ] = useState(false);

    function onSubmitEmail(e){
        e.preventDefault();
        const email = document.getElementById("recover-email-input").value;
        const response = sendEmailResetPasswordApi(email);
        setIsLoading(true)
        response.then(result => {
                window.location.href="/mail-sent";
            
        }).catch (err => {
            setIsLoading(false);
            console.log(err)
            handleDeleteButton();
        })
    }
    return(
    <div>
        <form onSubmit={(e) => onSubmitEmail(e)}>
            <div className="mb-3">
                <InputWithLabel text="Tu email" id="recover-email-input" placeholder="ejemplo@globalhits.com" />
            </div>
            <Button type="submit" disabled={isLoading ? true : false} classes={`a-button-primary1 ${isLoading ? "disabled" : ""}`} height={36} width="100%" text={isLoading ? <CircularProgress /> : "Enviar enlace"} />
        </form>
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
        >
            <div className='modal'>{modalBody}</div>
        </Modal>
    </div>
    )
}