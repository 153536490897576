import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Header from "../../../../components/molecules/Header";
import CardSkeleton from "../../../../components/molecules/Skeleton/CardSkeleton";
import { Error500connection } from "../../../../components/organisms/Error/500";
import { ExpandableCategoryCard } from "../../../../components/organisms/ExpandableCategoryCard/ExpandableCategoryCard";
import useCardList from "../../../../hooks/useCardList";

import Additional from '../../../../assets/img/resources/Additional.svg';
import GenerativeIA from "../../../../assets/img/resources/GenerativeIA.svg";
import Influencers from "../../../../assets/img/resources/influencers.svg";
import ConsultingSites from "../../../../assets/img/resources/consulting-sites.svg";
import SocialNetworks from "../../../../assets/img/resources/Social-network.svg";
import DesignProduct from "../../../../assets/img/resources/Design-product.svg";
import {Link} from "react-router-dom";
import {Button} from "../../../../components/atoms/Button";

export default function SearchResources({ userId, isCollapsed, type }) {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [error, setError] = useState(false);
  const [finalCategories, setFinalCategories] = useState([]);

  const { setDataArray, setIsThereData } = useCardList({
    initaldataArray: [],
    initialnumResult: 0,
  });

  const categoryMap = {
    resourceSitiosConsultoria: { name: 'Sitios de consultoría', icon: ConsultingSites },
    resourceIAGenerativa: { name: 'IA Generativa', icon: GenerativeIA },
    resourceInfluencers: { name: 'Influencers', icon: Influencers },
    resourceRedesSociales: { name: 'Redes sociales', icon: SocialNetworks },
    resourceDisenoProducto: { name: 'Diseño de producto', icon: DesignProduct },
    resourceAdicionales: { name: 'Adicionales', icon: Additional }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (!search) {
      setFinalCategories([]);
      return;
    }

    setIsLoading(true);
    setError(false);

    const controller = new AbortController();
    const signal = controller.signal;

    const url = `https://test.wikicid.com/by-search-resource?search=${search}`;
    console.log("🔎 Fetching data from:", url);

    fetch(url, { signal })
        .then((response) => {
          console.log("📡 Response status:", response.status);
          if (response.status === 404) {
            setFinalCategories([]); // No hay resultados
            setIsLoading(false);
            setIsThereData(false);
            return;
          }
          if (!response.ok) throw new Error(`Error al obtener los recursos: ${response.status}`);
          return response.json();
        })
        .then((res) => {
          if (!res) {
            setIsLoading(false);
            setFinalCategories([]);  // Si la respuesta es vacía, no procesamos nada
            setIsThereData(false);
            return;
          }

          console.log("📦 API Response Data:", res);
          const categories = convertToCategories(res);
          setFinalCategories(categories);
          setDataArray(res);
          setIsLoading(false);
          setIsThereData(res.length > 0);
        })
        .catch((err) => {
          console.error("Error al realizar la búsqueda:", err);
          setError(true);
          setIsLoading(false);
        });

    return () => controller.abort();
  }, [search]);

  function convertToCategories(responseData) {
    const categories = [];

    // Asegurarse de que responseData no es nulo o indefinido antes de procesar
    if (!responseData) return categories;

    Object.keys(responseData).forEach((key) => {
      if (key === "message") return;

      const category = {
        name: categoryMap[key]?.name || key,
        icon: categoryMap[key]?.icon || null,
        results: responseData[key].total || 0,
        resources: responseData[key].data || [],
      };
      categories.push(category);
    });
    console.log("categorias", categories);
    return categories;
  }

  return (
      <>
        <Header
            isCollapsed={isCollapsed}
            text="Buscar recurso"
            onChange={error ? "" : handleSearch}
            value={search}
            id="search-company-header"
            focus={true}
        />
        <div className="background-lg-first-neutral-10 br-2 h-100vh-header scroll-overflow pt-4 pt-lg-0">
          <div className="background-lg-white m-lg-4 p-lg-4 br-2 box-shadow-lg-all">
            <Grid container alignItems="center" justify="center" spacing={0}>
              <Grid item xs={10} lg={12}>
                <div className="d-flex justify-content-between">
                  <p className="h3-lg a-text-medium-second-neutral-70 align-self-end">Búsqueda de recursos</p>
                </div>
                {isLoading ? (
                    <CardSkeleton num={10}/>
                ) : finalCategories.length > 0 ? (
                    <div className="space-y-4 mt-4">
                      {finalCategories.map((category) => (
                          <ExpandableCategoryCard
                              key={category.name}
                              icon={<img src={category.icon} alt={category.name} style={{width: 24, height: 24}}/>}
                              title={category.name}
                              results={category.results}
                              resources={category.resources}
                          />
                      ))}
                    </div>
                ) : (
                    <div style={{marginTop: '20px'}}>No hay resultados disponibles.</div>
                )}
              </Grid>
            </Grid>
          </div>
        </div>

        {error && (
            <Grid item xs={12} className="background-lg-first-neutral-10 br-br-2 br-bl-2 pb-4">
              <div className="background-lg-white mx-lg-4 pt-4 br-br-2 br-bl-2">
                <Error500connection/>
              </div>
            </Grid>
        )}
      </>
  );
}
