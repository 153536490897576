import './_index.scss';
import Share from '../../../assets/img/icon/compartir.svg';
import React, { useState } from "react";
import ShareCard from "../ShareCard/ShareCard";
import {Link} from "react-router-dom";
import {IconButton} from "@material-ui/core";
import PencilIconOcean from "../../../assets/img/pencil-icon-ocean.svg";
import TrashIconOcean from "../../../assets/img/trash-icon-ocean.svg";
import AlertIcon from "../../../assets/img/alert.svg";
import {Button} from "../../atoms/Button";
import Modal from "@material-ui/core/Modal";
import {deleteResourceById} from "../../../pages/CID/Resources/ResourcesHelpers/ResourcesHelpers";


const ResourceInfoCard = ({ onClose, name, logo, url, id, description }) => {
    const [mostrarShareCard, setMostrarShareCard] = useState(false);
    const [mostrarResourceInfoCard, setMostrarResourceInfoCard] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [modalBody, setModalBody] = useState('');

    const toggleShareCard = () => {
        setMostrarResourceInfoCard(false);
        setMostrarShareCard(true);
    };

    const closeShareCard = () => {
        setMostrarShareCard(false);
        setMostrarResourceInfoCard(true);
    };

    function deleteResource(id) {
        deleteResourceById(id)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsOpen(false);
    }

    function handleDeleteButton(id) {
        setIsOpen(true);
        setModalBody(
            <>
                <div className='d-flex justify-content-center mb-4'>
                    <img
                        src={AlertIcon}
                        alt='Warning'
                    />
                </div>
                <p className='mb-4 a-text-medium-second-neutral-90 text-center'>
                    No se podrán deshacer los cambios.
                    <br />
                    ¿Seguro que deseas eliminar este recurso?
                </p>
                <div className='d-flex justify-content-center'>
                    <div
                        className='mr-3 w-100'
                        onClick={() => setIsOpen(false)}
                    >
                        <Button
                            type='button'
                            classes='a-button-primary1 text-small'
                            height={36}
                            width='100%'
                            text='Cancelar'
                        />
                    </div>
                    <div
                        onClick={() => deleteResource(id)}
                        className='w-100'
                    >
                        <Button
                            type='button'
                            classes='a-button-light text-small'
                            height={36}
                            width='100%'
                            text='Aceptar'
                        />
                    </div>
                </div>
            </>,
        );
    }
    return (
        <div className="share-card-overlay" onClick={onClose}>
            {mostrarResourceInfoCard && (
                <div className="share-card-modal" onClick={(e) => e.stopPropagation()}>
                    <div className="share-card-header">
                        <h5>{name}</h5>
                        <button className="close-button" onClick={onClose}>&times;</button>
                    </div>
                    <div className="description-card-body">
                        <div className="share-logo-container">
                            <img src={logo} alt={name} className="share-logo"/>
                            <div className="buttons-container">
                                {/*<Link to={`/cid/add-resources/${id}`} className="text-decoration-none w-100">*/}
                                <Link to={`/cid/update-resource/${id}`} className="text-decoration-none w-100">
                                    <IconButton>
                                        <img src={PencilIconOcean} alt="Pencil Icon"/>
                                    </IconButton>
                                </Link>

                                <IconButton onClick={() => handleDeleteButton(id)}>
                                    <img src={TrashIconOcean} alt="Trash Icon"/>
                                </IconButton>
                                <IconButton onClick={toggleShareCard}>
                                    <img src={Share} alt="Copy"/>
                                </IconButton>
                            </div>
                        </div>


                        <div className="description-card">
                            <p className="description-title">Descripción</p>
                            <p className="description">{description}</p>
                        </div>
                        <a href={url} target="_blank" rel="noopener noreferrer" className="share-open-link">
                            Abrir en una nueva pestaña
                        </a>
                    </div>
                </div>
            )}
            {mostrarShareCard && (
                <ShareCard name={name} logo={logo} url={url} onClose={() => {
                    closeShareCard();
                    onClose();
                }}/>
            )}
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
            >
                <div className='modal'>{modalBody}</div>
            </Modal>
        </div>
    );
};

export default ResourceInfoCard;
