import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FormUpdateResources from "../../../../components/organisms/FormUpdateResources";
import { getResourceId } from '../ResourcesHelpers/ResourcesHelpers';



export default function ResourcesUpdate() {
    const { id } = useParams();
    const [resourceData, setResourceData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchResourceData = async () => {
            try {
                setLoading(true);
                const data = await getResourceId(id);
                setResourceData(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchResourceData();
        }
    }, [id]);

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return <FormUpdateResources resourceData={resourceData} isUpdate={true} />;
}
