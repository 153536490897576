import React, { useState } from 'react';
import './ShareCard.scss';
import WhatsappIcon from '../../../assets/img/icon/whatsapp.svg';
import GmailIcon from '../../../assets/img/icon/gmail.svg';
import DriveIcon from '../../../assets/img/icon/drive.svg';
import OutlookIcon from '../../../assets/img/icon/outlook.svg';
import CopyIcon from '../../../assets/img/icon/copy.svg';

const ShareCard = ({ onClose, name, logo, url }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = function() {
        navigator.clipboard.writeText(url).then(function() {
            setCopied(true);
            setTimeout(function() { setCopied(false); }, 2000);
        });
    };

    const openWindow = function(link) {
        window.open(link, "_blank");
    };

    return (
        <div className="share-card-overlay" onClick={onClose}>
            <div className="share-card-modal" onClick={(e) => e.stopPropagation()}>
                <div className="share-card-header">
                    <h5>{name}</h5>
                    <button className="close-button" onClick={onClose}>&times;</button>
                </div>
                <div className="share-card-body">
                    <img src={logo} alt={name} className="share-logo" />
                    <a href={url} target="_blank" rel="noopener noreferrer" className="share-open-link">
                        Abrir en una nueva pestaña
                    </a>
                    <div className="share-card">
                    <p className="share-title">Compartir este archivo</p>
                    <div className="share-buttons">
                        <button className="share-btn" onClick={() => openWindow(`https://wa.me/?text=${encodeURIComponent(url)}`)}>
                            <img src={WhatsappIcon} alt="WhatsApp" className="w-40" />
                        </button>
                        <button className="share-btn" onClick={() => openWindow(`https://mail.google.com/mail/?view=cm&fs=1&body=${encodeURIComponent(url)}`)}>
                            <img src={GmailIcon} alt="Gmail" className="w-40" />
                        </button>
                        <button className="share-btn" onClick={() => openWindow("https://drive.google.com/drive/u/0/my-drive")}>
                            <img src={DriveIcon} alt="Drive" className="w-40" />
                        </button>
                        <button className="share-btn" onClick={() => openWindow(`https://outlook.live.com/mail/deeplink/compose?body=${encodeURIComponent(url)}`)}>
                            <img src={OutlookIcon} alt="Outlook" className="w-35" />
                        </button>
                    </div>
                    <div className="share-copy">
                        <input type="text" className="share-url" value={url} readOnly />
                        <button className="copy-button" onClick={handleCopy}>
                            <img src={CopyIcon} alt="Copy" className="copy-icon" />
                        </button>
                    </div>
                    {copied && <p className="copy-success">Copiado</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShareCard;
