import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { AudioController } from "../../../components/molecules/AudioPlayer/AudioController";
import { AudioPlayerProvider } from "react-use-audio-player";

import {
  Grid,
  useMediaQuery,
  createMuiTheme,
  IconButton,
  Badge,
  Modal,
} from "@material-ui/core/";

import { useAuth } from "../../../hooks/useAuth";

import Header from "../../../components/molecules/Header";
import MenuTabUser from "../../../components/molecules/TabMenu/twoOpt";
import { PodcastMenu } from "../../../components/organisms/MenuQuestionsUser/PodcastMenu";
import { CardPodcast } from "../../../components/molecules/Card/CardPodcast";
import { CardHorizontal } from "../../../components/molecules/Card/CardPodcast/CardHorizontal";
import SkeletonPodcast from "../../../components/molecules/Skeleton/SkeletonBlog/index";
import { WarningModal } from "../../../components/molecules/Modal/WarningModal";
import ModalSuccess from "../../../components/molecules/Modal/SuccessModal";
import PodcastStart from "../../../assets/img/PodcastBuhoPrincipal.png";
import { getPodcasts, getProgramsFiltered, podcastSaved } from "../../../api/podcasts";
import { Skeleton } from "@material-ui/lab";
import { Order } from "../../../components/organisms/Filters/Order/Order";
import { PodcastFilter } from "../../../components/organisms/Filters/PodcastFilters/PodcastFilter";

import FilterIconOcean from "../../../assets/img/filtrar.svg";
import OrderIconOcean from "../../../assets/img/order-icon-ocean.svg";
import {Button} from "../../../components/atoms/Button";

export const PodcastList = (e) => {
  const user = useAuth();
  const { userId } = user;
  const phone = useMediaQuery("(max-width: 400px)");
  const [oneCard, setOneCard] = useState(phone ? true : false)
  const [viewType, setViewType] = useState(true);
  const [isActiveList, setIsActiveList] = useState(true);
  const [isActiveActivi, setIsActiveActivi] = useState(false);
  // const [podcasts, setPodcasts] = useState(valueDefault);
  const [podcasts, setPodcasts] = useState();
  const [load, setLoad] = useState(true);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [loadDelete, setLoadDelete] = useState(true);
  const [deleteSuccess, setDeleteSuccess] = useState(true);
  const [podcast, setPodcast] = useState();

  //Estados para el filtrado y ordenamiento
  const [filterActive, setFilterActive] = useState(false)
  const [orderActive, setOrderActive] = useState(false)
  const [filters, setFilters] = useState([])
  const [orderType, setOrderType] = useState("rec")

  useEffect(() => {
    if (filters.length == 0) {
      if (orderType == "rec") {
        getAllPodcasts(false);
      } else {
        getAllPodcasts(true);
      }
    } else {

      if (orderType == "rec") {
        getAllPodcastsFiltered(filters, false);
      } else {
        getAllPodcastsFiltered(filters, true);
      }

    }

  }, [deleteSuccess, isActiveList, filters, orderType]);

  const theme = createMuiTheme({
    breakpoints: {
      values: {
        md: 768,
        lg: 992,
      },
    },
  });

  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  const iPadProMatch = useMediaQuery(theme.breakpoints.up("lg"));

  async function getAllPodcasts(order) {
    setLoad(true)
    try {
      if (!order) {
        setLoad(true);
        const response = await getPodcasts();
        console.log(response.data.podcast)
        setPodcasts(response.data.podcast);
        setLoad(false);
      } else {
        setLoad(true);
        const response = await getPodcasts();
        let aux = response.data.podcast
        let orderedPodcasts = orderingPodcasts(aux)
        setPodcasts(orderedPodcasts)
        setLoad(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAllPodcastsFiltered(categories, order) {
    try {

      if (!order) {
        setLoad(true);
        const response = await getProgramsFiltered(categories);
        console.log("Así llegan sin ordenamiento en un filtro", response.data.programs)
        setPodcasts(response.data.programs);
        setLoad(false);
      } else {
        setLoad(true);
        const response = await getProgramsFiltered(categories);
        let aux = response.data.programs
        let orderedPodcasts = orderingPodcasts(aux)
        setPodcasts(orderedPodcasts)
        setLoad(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function orderingPodcasts(podcast) {
    switch (orderType) {
      case "ant":
        podcast.reverse()
        return podcast
      case "az":
        podcast.sort((a, b) => a.title.localeCompare(b.title))
        return podcast
      case "za":
        podcast.sort((a, b) => -1 * a.title.localeCompare(b.title))
        return podcast
    }
  }

  // async function deleteObject(userId, podcastId) {
  //   try {
  //     setOpenSuccess(true);

  //     const deleted = await deletePodcast(userId, podcastId);

  //     if (deleted.status == 200) {
  //       setLoadDelete(false);

  //       setTimeout(() => {
  //         setOpenSuccess(false);
  //         setDeleteSuccess(!deleteSuccess);
  //       }, 500);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }
  return (
    <Grid container justify="center" spacing={0} className="">
      <Header
        className=""
        isCollapsed={e.isCollapsed}
        text="Buscar podcast"
      />
      <Grid
        item
        xs={12}
        lg={12}
        className="background-lg-first-neutral-10 h-100vh-header   scroll-overflow px-2 br-2 "
      >
        <div className="background-lg-white m-lg-4 p-lg-4 px-lg-4 br-2 px-md-5 px-3 box-shadow-lg-all ">
          <div className="d-flex w-100 ">
            <div className={` w-100  ${iPadProMatch ? "mt-0  " : "  "} `}>
              {" "}
              <p
                className={`mt-4 ${ipadMiniMatch
                    ? `h3   ${iPadProMatch ? "mt-0 " : "mt-6 "
                    }`
                    : ""
                  }  a-text-medium-second-neutral-70  `}
              >
                Podcast
              </p>{" "}
            </div>
            {iPadProMatch ? (
              filterActive || orderActive ? (
                ""
              ) : (
                <Link
                  to={"/cid/carso/podcast/upload"}
                  className={`text-normal ml-2  a-button-topic-blue-1 text-decoration-none  roboto-regular h3-md ml-md-4 text-lg-normal text-center d-flex justify-content-center align-items-center`}
                >
                  <Button
                    type="button"
                    classes="a-button-blue-1 text-small roboto-regular"
                    width="150px"
                    height="33px"
                    text="Subir podcast"
                  />
                </Link>
              )
            ) : (
              <div />
            )}
          </div>

          <div
            className={`${filterActive || orderActive
                ? "d-none"
                : "w-100 d-flex justify-content-end mt-1 mb-1"
              } `}
          >
            <div
              onClick={() => setOrderActive(!orderActive)}
              className=" pr-3 d-flex align-items-center filter-button cursor-pointer"
            >
              <IconButton className="IconOrder pl-0">
                <img src={OrderIconOcean} alt="Order" width="20px" />
              </IconButton>
              <p className="text-small a-text-regular-first-primary-60 filter-button__text">
                Ordenar
              </p>
            </div>
            <div
              onClick={() => setFilterActive(!filterActive)}
              className=" d-flex align-items-center filter-button cursor-pointer  "
            >
              <IconButton>
                <Badge badgeContent={filters.length} color="primary">
                  <img src={FilterIconOcean} alt="Filter" width="20px" />
                </Badge>
              </IconButton>
              <p className="text-small a-text-regular-first-primary-60 filter-button__text">
                Filtrar
              </p>
            </div>
          </div>

          <div className={`${orderActive ? "d-block" : "d-none"}`}>
            <Order
              onClick={() => setOrderActive(!orderActive)}
              orderType={orderType}
              setOrderType={setOrderType}
            // action={selectOrder}
            />
          </div>
          <div className={`${filterActive ? "d-block" : "d-none"}`}>
            <PodcastFilter
              onClick={() => setFilterActive(!filterActive)}
              filters={filters}
              setFilters={setFilters}
            />
          </div>

          <Grid
            xs={12}
            md={12}
            lg={12}
            className={`${!filterActive && !orderActive ? "d-block" : "d-none"}`}
          >
            <MenuTabUser
              optone={isActiveList}
              opttwo={isActiveList}
              optthird={isActiveActivi}
              setOptTwo={setIsActiveList}
              setOptThird={setIsActiveActivi}
              setViewType={setViewType}
              viewType={viewType}
            />
            {isActiveList ? (
              <>
                {!viewType ? (
                  !load ? (
                    <div className=" d-flex flex-wrap mt-2 w-100">
                      <>
                        {" "}
                        {podcasts?.map((podcast, idx) => (
                          <Grid container xs={6} sm={6} md={4} lg={3} justify="center" >
                            <CardPodcast
                              key={idx}
                              info={podcast}
                              userId={userId}
                              setElement={setPodcast}
                              setOpenWarning={setOpenWarning}
                              openWarning={openWarning}
                            />
                          </Grid>
                          // </div>
                        ))}{" "}
                      </>
                    </div>
                  ) : (
                    <div className=" d-flex flex-wrap mt-2 w-100">
                      <SkeletonPodcast num={3} />
                    </div>
                  )
                ) : (
                  !load ? (
                    <div className=" d-flex flex-wrap mt-2 w-100">
                      <>
                        {" "}
                        {podcasts?.map((podcast, idx) => (
                          <div key={idx} className="w-100">
                            <CardHorizontal
                              key={idx}
                              info={podcast}
                              userId={userId}
                              setElement={setPodcast}
                              setOpenWarning={setOpenWarning}
                              openWarning={openWarning}
                            />
                          </div>
                        ))}{" "}
                      </>
                    </div>
                  ) : (
                    <div className=" d-flex flex-wrap mt-2 w-100">
                      <SkeletonPodcast num={3} />
                    </div>
                  )
                )}
              </>
            ) : (
              <PodcastMenu />
            )}
          </Grid>
        </div>
      </Grid>
      <Modal
        open={openSuccess}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">
          <ModalSuccess
            open={openSuccess}
            setOpen={setOpenSuccess}
            message={`${loadDelete ? "Espera un momento..." : "Tu podcast se ha eliminado"
              }`}
            action={"Timmer"}
          />
        </div>
      </Modal>
      <Modal
        open={openWarning}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">
          <WarningModal
            message={"Tu podcast será eliminado"}
            open={openWarning}
            setOpen={setOpenWarning}
            setOpenSuccess={setOpenSuccess}
            openSuccess={openSuccess}
            object={podcast}
          />
        </div>
      </Modal>
    </Grid>
  );
};
