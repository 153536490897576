// Layouts
import LayoutMaster from "../layout/LayoutMaster";
import LayoutBasic from "../layout/LayoutBasic";
import LayoutAdmin from "../layout/LayoutAdmin";

// Admin
import AddUser from "../pages/Admin/AddUser";
import UpdateUser from "../pages/Admin/UpdateUser";
import UserList from "../pages/Admin/UserList";

// Login
import SignIn from "../pages/Comunidad/SignIn";
import SignInAdmin from "../pages/Admin/SignIn";
import UserVerify from "../pages/Comunidad/UserVerify";
import RecoverAccount from "../pages/Comunidad/RecoverAccount";
import MailSent from "../pages/Comunidad/MailSent";
import VerifyTokenPassword from "../pages/Comunidad/VerifyTokenPassword";
import SucessfulResetPassword from "../pages/Comunidad/SucessfulResetPassword"

// Home
import HomeCID from "../pages/CID/Home";
import LandingPage from "../pages/CID/LandingPage";
import HomeCARSO from '../pages/CARSO/Home/index';

// Company
import AddCompany from "../pages/CID/Company/AddCompany";
import UpdateCompany from "../pages/CID/Company/UpdateCompany";
import CompanyDetail from "../pages/CID/Company/CompanyDetail";
import CompanyList from "../pages/CID/Company/CompanyList";
//import SpectraPhantom from "../pages/CID/Company/SpectraPhantom";

// Reports
import AddReport from "../pages/CID/Report/AddReport";
import ReportsList from "../pages/CID/Report/ReportsList";
import UpdateReport from "../pages/CID/Report/UpdateReport";
import SpectraPhantomR from "../pages/CID/Report/SpectraPhantom";


//Resources
import ResourcesList from "../pages/CID/Resources/ResourcesList";
import ResourcesAdd from "../pages/CID/Resources/ResourcesAdd";
import SearchResources from "../pages/CID/Resources/SearchResources";



//Profile
import Profile from "../pages/Admin/Profile";
import ProfileFollowVist from "../pages/Admin/Profile/FollowProfileView/index";
import ProfileFollowList from "../pages/Admin/Profile/FollowsList/index";
import UpdateProfile from "../pages/Admin/Profile/UpdateProfile";

//todo
//import SpectraPhantomT from "../pages/CID/Home/SpectraPhantom";


//News

import News from '../pages/CARSO/News';

//Preguntas
import Topics from '../pages/CARSO/Topics/index'
import FirstQuestion from '../pages/CARSO/FirstQuestion/index'

import QuestionList from '../pages/CARSO/Questions/QuestionsList/index'
import ResponseList from "../pages/CARSO/ResponseList";
import QuestionListTuActividad from '../pages/CARSO/Questions/QuestionsTuActiv/index'

//Blog
import Blogs from '../pages/CARSO/Blogs';
import CreateBlog from '../pages/CARSO/Blogs/CreateBlogs';
import BlogList from '../pages/CARSO/Blogs/BlogList';

import Blog from '../pages/CARSO/Blogs/BlogDetail';
import BlogEdit from '../pages/CARSO/Blogs/BlogEdit';

import Publications from '../pages/CARSO/Blogs/Publications';

//Podcast
import { PodcastList } from "../pages/CARSO/Podcast/PodcastList";
import { UploadPodcast } from "../pages/CARSO/Podcast/UploadPodcast";
import { PodcastEpisode } from "../pages/CARSO/Podcast/PodcastEpisode";
import { PodcastProgram } from "../pages/CARSO/Podcast/PodcastProgram";

//Errors
import NotFound404 from "../pages/404"
import Error500 from "../pages/500"
import SpectraPhantom from "../pages/CID/Report/SpectraPhantom";
import ResourcesUpdate from "../pages/CID/Resources/ResourcesUpdate";


const routes = [
  // {
  //   path: "/admin",
  //   component: LayoutAdmin,
  //   exact: false,
  //   routes: [
     
  //     {
  //       path: "/admin/users/:id",
  //       component: UserList,
  //       exact: true,
  //     },
  //     {
  //       path: "/admin/users",
  //       component: UserList,
  //       exact: true,
  //     },
  //     {
  //       path: "/admin/add-user",
  //       component: AddUser,
  //       exact: true,
  //     },
  //     {
  //       path: "/admin/update-user/:id",
  //       component: UpdateUser,
  //       exact: true,
  //     }
  //   ]
  // },
  {
    path: "/cid",
    component: LayoutMaster,
    exact: false,
    routes: [

      //Admin
      {
        path: "/cid/admin/users/:id",
        component: UserList,
        exact: true,
      },
      {
        path: "/cid/admin/users",
        component: UserList,
        exact: true,
      },
      {
        path: "/cid/admin/add-user",
        component: AddUser,
        exact: true,
      },
      {
        path: "/cid/admin/update-user/:id",
        component: UpdateUser,
        exact: true,
      },
      {
        path: "/cid",
        component: HomeCID,
        exact: true
      },
      {
        path: "/cid/add-company",
        component: AddCompany,
        exact: true,
      },
      {
        path: "/cid/update-company/:id",
        component: UpdateCompany,
        exact: true,
      },
      {
        path: "/cid/company/:id",
        component: CompanyDetail,
        exact: true,
      },
      {
        path: "/cid/add-analysis",
        component: AddReport,
        exact: true,
      },
      {
        path: "/cid/update-analysis/:id",
        component: UpdateReport,
        exact: true,
      },
      {
        path: "/cid/search-company",
        component: SpectraPhantom,
        exact: true
      },
      // Buscador alternativo con base al ya implementado **********
      {
        path: "/cid/search-company-alternative",
        component: SpectraPhantom,
        exact: true
      },
      {
        path: "/cid/search-company-alternative",
        component: SpectraPhantom,
        exact: true
      },
      {
        path: "/cid/company",
        component: CompanyList,
        exact: true
      },
      {
        path: "/cid/analysis",
        component: ReportsList,
        exact: true
      },
      {
        path: "/cid/sdescargarApp",
        component: LandingPage,
        exact: true
      },  
      {
        path: "/cid/search-analysis",
        component: SpectraPhantomR,
        exact: true
      },
      {
        path: "/cid/resources",
        component: ResourcesList,
        exact: true
      },
      {
        path: "/cid/search-resources",
        component: SearchResources,
        exact: true
      },
      {
        path: "/cid/add-resources",
        component: ResourcesAdd,
        exact: true
      },
      {
        path: "/cid/update-resource/:id",
        component: ResourcesUpdate,
        exact: true
      },
      {
        path: "/cid/carso/questions",
        component: Topics,
        exact: true
      },
      {
        path: "/cid/carso/questions/list",
        component: QuestionList,
        exact: true
      },
      //Esta ruta es temporal, sólo es para el A/B Testing de los botones de tu actividad en preguntas
      {
        path: "/cid/carso/questions/list/tu_actividad",
        component: QuestionListTuActividad,
        exact: true
      },
      {
        path: "/cid/carso/questions/list/:id",
        component: ResponseList,
        exact: true,
      },
      {
        path: "/cid/carso/profile/:id",
        component: ProfileFollowVist,
        exact: true,
      },
      {
        path: "/cid/carso/questions/first",
        component: FirstQuestion,
        exact: true
      },
      { 
        path: "/cid/carso/profile",       
        component: Profile ,
        exact: true

      },
      {
        path: "/cid/carso/home",       
        component: HomeCARSO ,
        exact: true
      },
      {
        path: "/cid/carso/update-profile",
        component: UpdateProfile,
        exact: true,
      },
      {
        path: "/cid/carso/follows/:id",
        component: ProfileFollowList,
        exact: true,
      },
      {
        path: "/cid/carso/news",
        component: News,
        exact: true,
      },
      {
        path: "/cid/carso/blogs",
        component: Blogs,
        exact: true,
      },
      {
        path: "/cid/carso/blogs/create",
        component: CreateBlog,
        exact: true,
      },
      {
        path: "/cid/carso/blogs/list",
        component: BlogList,
        exact: true,
      },
      {
        path: "/cid/carso/blogs/list/:id",
        component: Blog,
        exact: true,
      },
      {
        path: "/cid/carso/blog/editar/:id",
        component: BlogEdit,
        exact: true,
      },
      {
        path: "/cid/carso/blogs/publications/:id",
        component: Publications,
        exact: true,
      },
      {
        path: "/cid/carso/podcast/list",
        component: PodcastList,
        exact: true,
      },
      {
        path: "/cid/carso/podcast/list/program/:id",
        component: PodcastProgram,
        exact: true,
      },
      {
        path: "/cid/carso/podcast/upload",
        component: UploadPodcast,
        exact: true,
      },
      {
        path: "/cid/carso/podcast/list/episode/:id",
        component: PodcastEpisode,
        exact: true,
      },
    ]
  }, 
  
  {
    path: "/",
    component: LayoutBasic,
    exact: false,
    routes: [
      {
        path: "/",
        component: SignIn,
        exact: true,
      },
      {
        path: "/user-verify",
        component: UserVerify,
        exact: false,
      },
      {
        path: "/recover-account",
        component: RecoverAccount,
        exact: true
      },
      {
        path: "/mail-sent",
        component: MailSent,
        exact: true
      },
      {
        path: "/reset-verify",
        component: VerifyTokenPassword,
        exact: true
      },
      {
        path: "/sucess-reset-password",
        component: SucessfulResetPassword,
        exact: true
      },
      { 
        path: "/500",       
        component: Error500 ,
        exact: true
      

      },
      { 
        path: "/404",       
        component: NotFound404 ,
        exact: true
      

      },

      // {
      //   path: "/signInAdmin",
      //   component: SignInAdmin,
      //   exact: true,
      // },
    
      
    ]
  }
];

export default routes;
