// React
import React, { useEffect, useState } from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';

// Material-UI
import { Grid } from '@material-ui/core';

// Componentes
import { ExpandableCategoryCard } from '../../../../components/organisms/ExpandableCategoryCard/ExpandableCategoryCard';
import { Button } from '../../../../components/atoms/Button';
import Header from '../../../../components/molecules/Header';

// API
import { getResources } from '../ResourcesHelpers/ResourcesHelpers';

// Imágenes
import Additional from '../../../../assets/img/resources/Additional.svg';
import GenerativeIA from "../../../../assets/img/resources/GenerativeIA.svg";
import Influencers from "../../../../assets/img/resources/influencers.svg";
import ConsultingSites from "../../../../assets/img/resources/consulting-sites.svg";
import SocialNetworks from "../../../../assets/img/resources/Social-network.svg";
import DesignProduct from "../../../../assets/img/resources/Design-product.svg";
import Paginations from "../../../../components/molecules/Paginations/Paginations";
import Modal from "@material-ui/core/Modal";
import SuccessIcon from "../../../../assets/img/sucess-icon.svg";

const categoryMap = {
    sitiosConsultoria: { name: 'Sitios de consultoría', icon: ConsultingSites },
    iaGenerativa: { name: 'IA Generativa', icon: GenerativeIA },
    influencers: { name: 'Influencers', icon: Influencers },
    redesSociales: { name: 'Redes sociales', icon: SocialNetworks },
    disenoProducto: { name: 'Diseño de producto', icon: DesignProduct },
    adicionales: { name: 'Adicionales', icon: Additional }
};

const categoryOrder = [
    'sitiosConsultoria',
    'iaGenerativa',
    'influencers',
    'redesSociales',
    'disenoProducto',
    'adicionales'
];

export default function Resources() {
    const [resources, setResources] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentResources, setCurrentResources] = useState([]);
    const [openSuccessEdited, setOpenSuccessEdited] = useState(localStorage.getItem("successEdited"));
    const [openSuccessCreated, setOpenSuccessCreated] = useState(localStorage.getItem("successCreated"));
    const [openSuccessDeleted, setOpenSuccessDeleted] = useState(localStorage.getItem("successDeleted"));
    const linkSearch = '/cid/search-resources';
    const history = useHistory();
    const location = useLocation();
    const [isCollapsed, setIsCollapsed] = useState(false);




    useEffect(() => {
        const fetchResources = async () => {
            const data = await getResources(currentPage);
            setResources(data);
        };
        fetchResources();
    }, [currentPage]);

    function extractCategories(resources) {
        return Object.keys(resources.resouces || {});
    }

    const selectedCategories = extractCategories(resources);

    const mappedCategories = selectedCategories.map(selectedCategory => {
        const category = categoryMap[selectedCategory];

        if (category) {
            return {
                name: category.name,
                icon: category.icon,
                results: resources.resouces[selectedCategory]?.total || 0,
                resources: resources.resouces[selectedCategory]?.data || []
            };
        }

        return null;
    }).filter(category => category !== null);

    // Encontrar el máximo valor de "results"
    const totalCompanies = mappedCategories.reduce((max, category) =>
        category.results > max ? category.results : max, 0);

    console.log("Total de recursos (categoría con más resultados):", totalCompanies);

    const orderedCategories = categoryOrder
        .map(categoryKey => mappedCategories.find(category => category.name === categoryMap[categoryKey].name))
        .filter(category => category !== undefined);

    const finalCategories = [
        ...orderedCategories.filter(category => category.results > 0),
        ...orderedCategories.filter(category => category.results === 0)
    ];


    function paginate(page) {
        console.log('Página actual:', page);  // Imprime el valor de page

        setCurrentPage(page);

        const startIndex = (page - 1) * 10;
        const endIndex = page * 10;

        const paginatedResources = finalCategories.map(category => {
            return {
                ...category,
                resources: category.resources.slice(startIndex, endIndex)
            };
        });

        setCurrentResources(paginatedResources);
    }

    function handleChangeHeader(e) {
        history.push({
            pathname: '/cid/search-resources',
            state: {
                value: e.target.value,
                location: location.pathname,
            },
        });
    }

    return (
        <>
            <Header
                isCollapsed={isCollapsed}
                link={linkSearch}
                text={'Buscar recurso'}
                focus={true}
                onChange={(e) => handleChangeHeader(e)}
            />

            <div className="background-lg-first-neutral-10 br-2 h-100vh-header scroll-overflow pt-4 pt-lg-0">
                <div className="background-lg-white m-lg-4 p-lg-4 br-2 box-shadow-lg-all">
                    <Grid container alignItems="center" justify="center" spacing={0}>
                        <Grid item xs={10} lg={12}>
                            <div className="d-flex justify-content-between">
                                <p className="h3-lg a-text-medium-second-neutral-70 align-self-end">Catálogo de recursos</p>
                                <Link to="/cid/add-resources" className="d-none d-lg-block">
                                    <Button
                                        type="button"
                                        classes="a-button-blue-1 text-small roboto-regular"
                                        width="150px"
                                        height="33px"
                                        text="Añadir recurso"
                                    />
                                </Link>
                            </div>

                            <div className="space-y-4 mt-4">
                                {finalCategories.map((category) => (
                                    <ExpandableCategoryCard
                                        key={category.name}
                                        icon={<img src={category.icon} alt={category.name} style={{ width: 24, height: 24 }} />}
                                        title={category.name}
                                        results={category.results}
                                        resources={category.resources}
                                    />
                                ))}
                            </div>
                        </Grid>
                    </Grid>
                    <Paginations
                        postPerPage={10}
                        totalCompanies={totalCompanies}
                        paginate={paginate}
                        currentPage={currentPage}
                    />
                </div>
                <Modal
                    open={openSuccessEdited}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className="modal">
                        <div className="d-flex justify-content-center">
                            <img
                                src={SuccessIcon}
                                alt="Success Icon"
                                className="mb-3 modal__success-icon-modal"
                            />
                        </div>
                        <p className="mb-3 a-text-medium-second-neutral-90 text-center">
                            Se actualizaron los datos correctamente.
                        </p>
                        <div className="d-flex justify-content-center">
                            <div className="w-50">
                                <Button
                                  onclick={() => {
                                  setOpenSuccessEdited(false);
                                  localStorage.removeItem("successEdited");
                                }}
                                  type="button"
                                  classes="a-button-primary1"
                                  height={36}
                                  width="100%"
                                  text="Aceptar"
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={openSuccessCreated}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className="modal">
                        <div className="d-flex justify-content-center">
                            <img
                            src={SuccessIcon}
                            alt="Success Icon"
                            className="mb-3 modal__success-icon-modal"
                        />
                        </div>
                        <p className="mb-3 a-text-medium-second-neutral-90 text-center">
                        Se añadió el recurso correctamente.
                        </p>
                        <div className="d-flex justify-content-center">
                            <div className="w-50">
                                <Button
                                  onclick={() => {
                                  setOpenSuccessCreated(false);
                                  localStorage.removeItem("successCreated");
                                }}
                                  type="button"
                                  classes="a-button-primary1"
                                  height={36}
                                  width="100%"
                                  text="Aceptar"
                                />
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    open={openSuccessDeleted}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className="modal">
                        <div className="d-flex justify-content-center">
                            <img
                            src={SuccessIcon}
                            alt="Success Icon"
                            className="mb-3 modal__success-icon-modal"
                        />
                        </div>
                        <p className="mb-3 a-text-medium-second-neutral-90 text-center">
                        Se elimino el recurso correctamente.
                        </p>
                        <div className="d-flex justify-content-center">
                            <div className="w-50">
                                <Button
                                  onclick={() => {
                                  setOpenSuccessDeleted(false);
                                  localStorage.removeItem("successDeleted");
                                }}
                                  type="button"
                                  classes="a-button-primary1"
                                  height={36}
                                  width="100%"
                                  text="Aceptar"
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
}
