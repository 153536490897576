import './_index.scss';
import Header from '../../molecules/Header';
import { Button } from '../../atoms/Button/';
import { useAuth } from "../../../hooks/useAuth";
import { Label } from "../Form/FormCompany/Label";
import React, { useState, useEffect, useRef} from 'react';
import Close from '../../../assets/img/icon/close.svg';
import { SelectScroll } from '../../atoms/Input/SelectScroll.js';
import { CircularProgress, Grid, IconButton } from '@material-ui/core';
import { InputWithLabel } from '../../molecules/InputWith/InputWithLabel';
import { AutoexpansibleInput } from "../../atoms/Input/AutoexpansibleInput";
import { updateResource } from "../../../pages/CID/Resources/ResourcesHelpers/ResourcesHelpers";



const classificationOptions = [
    { name: 'Sitios de consultoría', classification_id: 1 },
    { name: 'IA Generativa', classification_id: 2 },
    { name: 'Influencers', classification_id: 3 },
    { name: 'Redes sociales', classification_id: 4 },
    { name: 'Diseño de producto', classification_id: 5 },
    { name: 'Adicionales', classification_id: 6 },
];

const typeOptions = [
    { name: 'Enlace Web (Link)', rolId: 1 },
    { name: 'Archivo', rolId: 2 },
];

export default function FormUpdateResources({ isCollapsed, resourceData, resourceId }) {
    const user = useAuth();
    const user_id = user?.userId;
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [classificationValue, setClassificationValue] = useState( "");
    const [typeValue, setTypeValue] = useState("");
    const [logo, setLogo] = useState("");
    const [description, setDescription] = useState( "");
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const hasLogged = useRef(false);


    const filterClassificationOptions = (typeValue, classificationOptions) => {
        if (typeValue === "1") {
            return classificationOptions.filter(option => option.classification_id !== 6);
        }
        if (typeValue === "2") {
            return classificationOptions.filter(option => option.classification_id === 6);
        }
        return classificationOptions;
    };

    const filteredClassificationOptions = filterClassificationOptions(typeValue, classificationOptions);


    useEffect(() => {
        if (resourceData && !hasLogged.current) {
            setClassificationValue(resourceData.classification_id || "");
            setTypeValue(resourceData.type_id || "");
            setDescription(resourceData.description || "");
            setName(resourceData.name || "");
            setLogo(resourceData.logo_url || "");
            setUrl(resourceData.url || "");
            hasLogged.current = true;
        }
    }, [resourceData]);

    const handleNameChange = (e) => {
        setName(e.target.value);
    };


    const handleFileChange = (e) => {
        const selectedFile = e.target.files?.[0];
        if (selectedFile) {
            setFile(selectedFile);
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                setFilePreview(fileReader.result);
            };

            if (selectedFile.type === "application/pdf") {
                fileReader.readAsDataURL(selectedFile);
            } else if (selectedFile.type.startsWith("image")) {
                fileReader.readAsDataURL(selectedFile);
            }
        }
    };


    const createFormData = (name, classificationValue, typeValue, description, url, file, resourceData, user_id, id) => {
        const formData = new FormData();
        formData.append('resourceId',  id);
        formData.append('name', name);
        formData.append('classification_id', classificationValue);
        formData.append('type_id', typeValue);
        formData.append('description', description);
        formData.append('user_id', user_id);

        if (Number(typeValue) === 1) {
            if (file) formData.append('file_logo_url', file);
            if (url) formData.append('url', url);
        } else {
            if (file) formData.append('file_url', file);
        }
        return formData;
    };

    const handleResponse = () => {
        setIsLoading(false);
        localStorage.setItem("successEdited", "true");
        console.log("Valor de successEdited: ", localStorage.getItem("successEdited"));
        window.location.href = "/cid/resources";
    };

    const handleError = (error) => {
        console.error("Error:", error);
        setIsLoading(false);
    };

    function submitForm(e) {
        e.preventDefault();
        setIsLoading(true);

        const name = document.getElementById('add-resource-name')?.value || "";
        const url = document.getElementById('add-resource-url')?.value || "";
        const id = resourceData?.id


        const formData = createFormData(name, classificationValue, typeValue, description, url, file, resourceData, user_id, id);

        updateResource(formData)
            .then(response => handleResponse(response, !!resourceData))
            .catch(handleError);
    }


    return (
        <Grid container alignItems='center' spacing={0}>
            <Header hideMessageAndNoti={true} text={resourceData ? 'Actualizar recurso' : 'Añadir recurso'} isCollapsed={isCollapsed} />
            <Grid item xs={12} alignItems='center'>
                <div className='background-lg-first-neutral-10 br-2 h-100vh-header scroll-overflow pt-4 pt-lg-0'>
                    <div className='background-lg-white m-lg-4 p-lg-4 br-2 box-shadow-lg-all'>
                        <div className='d-flex justify-content-between'>
                            <p className='ml-3 h3-lg text-big a-text-bold-second-neutral-70 align-self-center'>
                                Formulario de recursos
                            </p>
                            <IconButton>
                                <img
                                    src={Close}
                                    alt='Close'
                                    className='nav-company-icon'
                                    onClick={() => window.location.href = '/cid/resources'}
                                />
                            </IconButton>
                        </div>
                        <form onSubmit={submitForm} className='ml-3 w-90 w-lg-60'>
                            <p className='a-text-regular-first-neutral-100 text-small mb-3'>* Campos obligatorios</p>

                            <div className='mb-6'>
                                <Label text='Nombre *'/>
                                <InputWithLabel
                                    classLabel='mb-2'
                                    id='add-resource-name'
                                    placeholder='Nombre(s)'
                                    type='text'
                                    value={name}
                                    onChange={handleNameChange}

                                />
                            </div>

                            <div>
                                <Label text='Tipo *'/>
                                <SelectScroll
                                    data={typeOptions}
                                    nameKey={(elem) => elem.name}
                                    IdKey={(elem) => elem.rolId}
                                    id="add-resource-type"
                                    placeholder="Selecciona un tipo"
                                    dateChanged={(e) => setTypeValue(e.target.value)}
                                    value={resourceData?.type_id}
                                    selectClass="position-absolute-list"
                                    disabled={true}
                                />
                            </div>

                            <div>
                                <Label text='Clasificación *'/>
                                <SelectScroll
                                    data={filteredClassificationOptions}
                                    nameKey={(elem) => elem.name}
                                    IdKey={(elem) => elem.classification_id}
                                    id="add-resource-classification"
                                    placeholder="Selecciona una clasificación"
                                    dateChanged={(e) => setClassificationValue(e.target.value)}
                                    value={resourceData?.classification_id}
                                    selectClass="position-absolute-list"
                                    disabled={true}
                                />
                            </div>

                            {typeValue === 1 && (
                                <>
                                    <div className='mb-6'>
                                        <Label text='Ingresa el enlace web del sitio *'/>
                                        <InputWithLabel
                                            classLabel='mb-2'
                                            id='add-resource-url'
                                            placeholder='https://www.example.com/'
                                            type='text'
                                            value={url}
                                            onChange={(e) => setUrl(e.target.value)}
                                        />
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor="logo" className="mb-2">Logo *</label>
                                        <input
                                            id="add-resource-logo"
                                            type="file"
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            className="form-control"
                                        />
                                    </div>
                                    {(filePreview || logo) && (
                                        <img
                                            src={filePreview || logo}
                                            alt="Vista previa"
                                            style={{ width: '250px', height: 'auto' }}
                                        />
                                    )}
                                </>
                            )}

                            {typeValue === 2 && (
                                <div className="mb-6">
                                    <label htmlFor="archivo" className="mb-2">Archivo *</label>
                                    <input
                                        id='add-resource-url'
                                        type="file"
                                        accept=".pdf,.doc,.docx,.xls,.xlsx"
                                        onChange={handleFileChange}
                                        className="form-control"
                                    />
                                </div>
                            )}

                            {filePreview && !filePreview.startsWith("data:image") && (
                                <div className="file-preview">
                                    <p>Archivo seleccionado: {file.name}</p>
                                    {file.type === "application/pdf" && <embed src={filePreview} type="application/pdf" width="100%" height="500px" />}
                                </div>
                            )}

                            <div className='mb-6'>
                                <Label text='Descripción *'/>
                                <AutoexpansibleInput
                                    placeholder='Ingresa la descripción del recurso '
                                    setText={setDescription}
                                    text={description}
                                />
                            </div>

                            <Button
                                type='submit'
                                classes='a-button-primary1'
                                height={36}
                                width='100%'
                                text={isLoading ? <CircularProgress /> : 'Actualizar recurso'}
                            />
                        </form>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}
