// React
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Material
import {
  useMediaQuery,
  Modal,
  Grid,
  IconButton,

} from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'

import { Skeleton } from "@material-ui/lab";

// Componentes
import { InputWithLabel } from "../../molecules/InputWith/InputWithLabel";
import { Button } from "../../atoms/Button/";
import { AddButton } from "../../molecules/AddButton";
// Images
import CircularProgress from "@material-ui/core/CircularProgress";
import FailIcon from "../../../assets/img/fail-icon.svg";
import Close from "../../../assets/img/icon/close.svg";

// Api
import {
  getReports,
  getReport,
  updateReports,
  getSectorsReport,
  getMarketsReport,
  getClients,
} from "../../../api/report";

import useFormUpdateReport from "../../../hooks/useFormUpdateReport";
import useFromUpdateReportErrros from "../../../hooks/useFormUpdateReportErrors";
import { Error500connection } from "../Error/500";
function FormUpdateReport({ userId, reportId }) {
  const {
    isLoading,
    checkboxSector,
    checkboxClient,
    checkboxMarket,
    buttonSector,
    buttonMarket,
    open,
    modalBody,
    file,
    nameFile,
    report,
    client,
    sector,
    market,
    checkSectorUpdate,
    checkMarketUpdate,
    checkClientUpdate,
    statusButtonSector,
    statusButtonMarket,
    statusButtonClient,
    images,
    imagesNew,
    progress,
    setIsLoading,
    setCheckboxSector,
    setCheckboxMarket,
    setCheckboxClient,
    setButtonSector,
    setButtonMarket,
    setOpen,
    setModalBody,
    setFile,
    setNameFile,
    setReport,
    setClient,
    setMarket,
    setSector,
    setCheckSectorUpdate,
    setCheckMarketUpdate,
    setCheckClientUpdate,
    setStatusButtonSector,
    setStatusButtonMarket,
    setStatusButtonClient,
    setImages,
    setImagesNew,
    setProgress,
  } = useFormUpdateReport({
    initialLoading: false,
    initialCheckboxSector: [],
    initialCheckboxClient: [],
    initialCheckboxMarket: [],
    initialButtonSector: false,
    initialButtonMarket: false,
    initialOpenState: false,
    initialModalBody: "",
    initialFile: [],
    initialnameFile: "",
    initialreport: {},
    initialclient: [],
    initialsector: [],
    initialmarket: [],
    initialcheckSectorUpdate: [],
    initialcheckMarketUpdate: [],
    initialcheckClientUpdate: [],
    initialstatusButtonSector: false,
    initialstatusButtonMarket: false,
    initialstatusButtonClient: false,
    initialimages: [],
    initialimagesNew: [],
    initialProgress: 10,
  });

  // Status err
  const {
    warningTitle,
    warningDescription,
    warningFile,
    warningClient,
    warningSector,
    warningMarket,
    warningImagen,
    setWarningTitle,
    setWarningDescription,
    setWarningFile,
    setWarningClient,
    setWarningSector,
    setWarningMarket,
    setWarningImagen,
  } = useFromUpdateReportErrros({
    initialwarningTitle: "",
    initialwarningDescription: "",
    initialwarningFile: "",
    initialwarningClient: "",
    initialwarningSector: "",
    initialwarningMarket: "",
    initialwarningImagen: "",
  });

  const [sectorTemporal, setSectorTemporal] = useState({ sectores: [] })
  const [marketTemporal, setMarketTemporal] = useState({ markets: [] })
  const [skeleton, setSkeleton] = useState(false);

  const history = useHistory();

  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  //const theme = useTheme();
  const theme = createTheme({
    breakpoints: {
      values: {
        md: 768,
        lg: 992,
      },
    },
  });
  //const phoneMatch = useMediaQuery(theme.breakpoints.down('md'));
  const iPadMatch = useMediaQuery(theme.breakpoints.between(768, 992));
  const iPadProMatch = useMediaQuery(theme.breakpoints.up("lg"));

  // Peticiones
  useEffect(() => {
    const reports = getReport(userId, reportId);
    reports.then((res) => {
      if (res.status === 200) {
        console.log(res);
        setReport(res.data.report);
        setCheckSectorUpdate(res.data.report.sectors);
        setCheckMarketUpdate(res.data.report.markets);
        setCheckClientUpdate(res.data.report.clients);
        var file_url = res.data.report.file_url;
        var fileName = file_url.substring(file_url.lastIndexOf("/") + 1);
        setFile(file_url);
        setNameFile(fileName);
      }
    }).catch(err => {
      console.log(err);
      setSkeleton(true);
    });

    const sectors = getSectorsReport(userId);
    sectors.then((result) => {
      if (result.status === 200) {
        setCheckboxSector(result.data.sectors);
      }
    }).catch(err => {
      console.log(err);
      setSkeleton(true);
    });
    const response = getMarketsReport(userId);
    response.then((result) => {
      if (result.status === 200) {
        setCheckboxMarket(result.data.markets);
      }
    }).catch(err => {
      console.log(err);
      setSkeleton(true);
    });
    const clientsResponse = getClients(userId);
    clientsResponse.then((result) => {
      if (result.status === 200) {
        setCheckboxClient(result.data.clientes);
      }
    }).catch(err => {
      console.log(err);
      setSkeleton(true);
    });
  }, []);

  // Funciones
  const handleInputChange = (e) => {
    setReport(e.target.value);
  };

  // ? Estas 2 funciones estan pendientes de revision
  // function addSector() {
  //   setButtonSector(!buttonSector)
  //   const sector = document.getElementById("sector").value
  //   if (sector != "") {
  //     setCheckboxSector([...checkboxSector, { name: sector, sector_id: checkboxSector.length + 1 }])
  //   }
  // }
  // function addMarket() {
  //   setButtonMarket(!buttonMarket)
  //   const market = document.getElementById("market").value
  //   if (market != "") {
  //     setCheckboxMarket([...checkboxMarket, { name: market, market_id: checkboxMarket.length + 1 }])
  //   }
  // }

  function uploadFile(e) {
    var fileInput = document.getElementById("report-document");
    var filePath = fileInput.value;
    var allowedExtension = /(.pdf)$/i;
    if (!allowedExtension.exec(filePath)) {
      console.log("solo pdf.");
      setOpen(true);
      setModalBody(
        <>
          <div className="d-flex justify-content-center">
            <img
              src={FailIcon}
              alt="Fail Icon"
              className="mb-3 modal__icon-modal"
            />
          </div>
          <p className="mb-3 a-text-medium-second-neutral-90 text-center">
            Intenta con un archivo con extensión .pdf
          </p>
          <div className="d-flex justify-content-center">
            <div className="w-50" onClick={handleClose}>
              <Button
                type="button"
                classes="a-button-primary1"
                height={36}
                width="100%"
                text="Aceptar"
              />
            </div>
          </div>
        </>
      );
      fileInput.value = "";
      return false;
    } else {
      //Image preview
      if (fileInput.files && fileInput.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          setNameFile(fileInput.files[0].name);
        };
        reader.readAsDataURL(fileInput.files[0]);
        setFile(reader.result);
      }
    }
  }
  function uploadImg(e, i) {
    var fileInput = document.getElementById("report-client-img");
    var filePath = fileInput.value;
    var allowedExtension = /(.jpeg|.png|jpg|.gif)$/i;
    if (!allowedExtension.exec(filePath)) {
      setOpen(true);
      setModalBody(
        <>
          <div className="d-flex justify-content-center">
            <img
              src={FailIcon}
              alt="Fail Icon"
              className="mb-3 modal__icon-modal"
            />
          </div>
          <p className="mb-3 a-text-medium-second-neutral-90 text-center">
            Intenta con un archivo con extensión .png, .jpg{" "}
          </p>
          <div className="d-flex justify-content-center">
            <div className="w-50" onClick={handleClose}>
              <Button
                type="button"
                classes="a-button-primary1"
                height={36}
                width="100%"
                text="Aceptar"
              />
            </div>
          </div>
        </>
      );
      fileInput.value = "";
      return false;
    } else {
      //Image preview
      if (fileInput.files && fileInput.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(fileInput.files[0]);
        reader.onload = function (e) {
          const arrImages = [...images];
          arrImages.splice(i, 1, reader.result);
          setImages(arrImages);
        };
        //console.log(reader.result)
        //setLogo(reader.result)
      }
    }
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAddClient = () => {
    setStatusButtonClient(!statusButtonClient);
    if (images[0] != "") {
      const arrImages = [...images];
      arrImages.splice(0, 1, "");
      setImages(arrImages);
    }
  };
  const handleBack = () => {
    window.location.href = "/cid/analysis";
  };
  function handleButton(id) {
    switch (id) {
      case "sector":
        return (
          <>
            {!statusButtonSector ? (
              ""
            ) : (
              <div className="mb-3">
                <InputWithLabel
                  Location="true"
                  id="report-sector"
                  placeholder="Ingresa el nombre de un sector"
                  focus={true}
                  type="addInput"
                  onClick={() => handleStatusButton("sector")}
                />
              </div>
            )}
            <div
              className="mb-3"
              onClick={!statusButtonSector ? addFiend : removeFiend}
            >
              <Button
                type="button"
                classes="a-button-light-blue"
                id="add-sector"
                height={iPadMatch ? 40 : 32}
                width={iPadProMatch ? 255 : "60%"}
                text={!statusButtonSector ? "Agregar sector" : "Cancelar"}
              />
            </div>
          </>
        );
      case "market":
        return (
          <>
            {!statusButtonMarket ? (
              ""
            ) : (
              <div className="mb-3">
                <InputWithLabel
                  Location="true"
                  id="report-market"
                  placeholder="Ingresa el nombre de una tecnología"
                  focus={true}
                  type="addInput"
                  onClick={() => handleStatusButton("market")}
                />
              </div>
            )}
            <div
              className="mb-3"
              onClick={!statusButtonMarket ? addFiend : removeFiend}
            >
              <Button
                type="button"
                classes="a-button-light-blue"
                id="add-market"
                height={iPadMatch ? 40 : 32}
                width={iPadProMatch ? 255 : "60%"}
                text={!statusButtonMarket ? "Agregar tecnología" : "Cancelar"}
              />
            </div>
          </>
        );
      case "client":
        return (
          <>
            {!statusButtonClient ? (
              ""
            ) : (
              <div className="mb-3">
                <InputWithLabel
                  text="Logo"
                  id="report-client-img"
                  type="logoFile"
                  width={155}
                  Location={true}
                  value={images[0]}
                  onChange={(e) => {
                    uploadImg(e, 0);
                  }}
                />
                <InputWithLabel
                  text="Nombre del cliente *"
                  Location="true"
                  id="report-client"
                  placeholder="Ingresa el nombre de un cliente"
                  focus={true}
                  type="add"
                  onClick={() => handleStatusButton("client")}
                  classLabel="mt-3"
                />
              </div>
            )}
            <div className="mb-3" onClick={handleCloseAddClient}>
              {/*
              <Button
                type="button"
                classes="button-form text-small minuta_btn-add"
                id="add-client"
                height={iPadMatch ? 40 : 32}
                width={iPadProMatch ? 255 : "60%"}
                text={!statusButtonClient ? "Agregar cliente" : "Cancelar"}
              />
              */}
            </div>
          </>
        );
      default:
        break;
    }
    function addFiend(e) {
      switch (e.target.id) {
        case "add-sector":
          setStatusButtonSector(!statusButtonSector);
          setSector([...sector, ""]);
          break;
        case "add-market":
          setStatusButtonMarket(!statusButtonMarket);
          setMarket([...market, ""]);
          break;
        case "add-client":
          console.log("buttonClient", statusButtonClient);
          setStatusButtonClient(!statusButtonClient);
          setClient([...client, ""]);
          break;

        default:
          break;
      }
    }
    function removeFiend(e) {
      switch (e.target.id) {
        case "add-sector":
          setStatusButtonSector(!statusButtonSector);
          const arrSector = [...sector];
          setSector(arrSector.splice(1, sector.length));
          break;
        case "add-market":
          setStatusButtonMarket(!statusButtonMarket);
          const arrMarket = [...market];
          setMarket(arrMarket.splice(1, market.length));
          break;
        case "add-client":
          console.log("buttonClient", statusButtonClient);
          setStatusButtonClient(!statusButtonClient);
          const arrClient = [...client];
          setClient(arrClient.splice(1, client.length));
          if (images[0] != "") {
            const arrImages = [...images];
            arrImages.splice(0, 1, "");
            setImages(arrImages);
          }

          break;

        default:
          break;
      }
    }
  }
  function handleStatusButton(id) {
    switch (id) {
      case "sector":
        setStatusButtonSector(!statusButtonSector);
        const valSector = document.getElementById("report-sector").value;
        if (valSector != "") {
          setCheckboxSector([
            ...checkboxSector,
            { name: valSector, sector_id: checkboxSector.length + 1 },
          ]);
        }
        break;
      case "market":
        setStatusButtonMarket(!statusButtonMarket);
        const valMarket = document.getElementById("report-market").value;
        if (valMarket != "") {
          setCheckboxMarket([
            ...checkboxMarket,
            { name: valMarket, market_id: checkboxMarket.length + 1 },
          ]);
        }
        break;
      case "client":
        setStatusButtonClient(!statusButtonClient);
        const valClient = document.getElementById("report-client").value;
        const logoClient = images[0];
        const clientlogo =
          document.getElementById("report-client-img").files[0] || "";
        console.log(clientlogo, "file client");
        setImagesNew([...imagesNew, { clientlogo }]);
        const activ = 1;
        if (valClient != "") {
          setCheckboxClient([
            ...checkboxClient,
            {
              client_id: checkboxClient.length + 1,
              name: valClient,
              logo_client: logoClient,
              act: activ,
            },
          ]);
        }
        const arrImages = [...images];
        arrImages.splice(0, 1, "");
        setImages(arrImages);
        console.log(checkboxClient, "add client");
        break;

      default:
        break;
    }
  }
  function handleChacbox(name) {
    console.log(name);
  }
  // Sumit
  function submitCreateForm(e) {
    e.preventDefault();
    const formData = new FormData();
    const user = userId;
    const report = reportId;
    console.log(report);
    const title = document.getElementById("report-title");
    const description = document.getElementById("report-description");
    const filepdf = document.getElementById("report-document");
    const sectorInputs = document.querySelectorAll(".report-sector:checked");
    // const sectors = [];
    // sectorInputs.forEach((sector) => {
    //   sectors.push(sector.value);
    // });
    // const marketInputs = document.querySelectorAll(".report-market:checked");
    // const markets = [];
    // marketInputs.forEach((market) => {
    //   markets.push(market.value);
    // });
    const clientInputs = document.querySelectorAll(".report-client:checked");
    const clients = [];
    const logos = [];
    clientInputs.forEach((client) => {
      let clientData = JSON.parse(client.value);
      clients.push(clientData.name);
      logos.push(clientData.logo);
    });

    //Validaciones front

    if (!title.value) {
      setWarningTitle("Ingresa el título del análisis");
      title.classList.add("input-error");
    } else {
      setWarningTitle("");
      title.classList.remove("input-error");
    }

    if (!description.value) {
      setWarningDescription("Ingresa una descripción");
      description.classList.add("input-error");
    } else {
      setWarningDescription("");
      description.classList.remove("input-error");
    }

    let sectors = [];
    sectorTemporal.sectores.forEach(sector => sectors.push(sector.name));
    checkSectorUpdate.forEach(sector => sectors.push(sector.name));

    let tecnologias = [];
    marketTemporal.markets.forEach(market => tecnologias.push(market.name));
    checkMarketUpdate.forEach(market => tecnologias.push(market.name))

    if (sectors.length == 0) {
      setWarningSector("Selecciona al menos un sector");
    } else {
      setWarningSector("");
    }

    if (tecnologias.length == 0) {
      setWarningMarket("Selecciona al menos una tecnología");
    } else {
      setWarningMarket("");
    }

    if (clients.length == 0) {
      setWarningClient("Selecciona al menos un cliente");
    } else {
      setWarningClient("");
    }

    //Validaciones front

    const lg = []; //para obtener los que ya estan registrados
    const wr = []; // para obtener los nuevos clientes

    console.log(clients, "clientes");
    console.log(logos, "logos");

    logos.forEach((log) => {
      //llenar los arreglos y desechar los file que no funcionan
      if (log) {
        log.includes("data") ? wr.push(log) : lg.push(file);
      } else {
        lg.push(null);
      }
    });
    console.log(imagesNew, "imagesNew");
    imagesNew.forEach((logend) => {
      //unificar los que escogio de los actuales y los nuevos clientes que subio
      lg.push(logend.clientlogo);
    });

    console.log("userId", user);
    console.log("reportId", report);
    console.log("title", title.value);
    console.log("description", description.value);
    console.log("file", filepdf.files[0]);

    console.log("sector", sectors);
    console.log("market", tecnologias);

    console.log("clientes", clients);

    formData.append("userId", user);
    formData.append("reportId", report);
    formData.append("title", title.value);
    formData.append("description", description.value);
    if (filepdf.files[0]) {
      formData.append("file", filepdf.files[0]);
    }
    // formData.append("file", filepdf.files[0]);
    formData.append("sector", sectors);
    formData.append("market", tecnologias);

    formData.append("clientes", clients);
    var index = 0;
    while (index < lg.length) {
      //mandar los clientes de acuerdo a su forma
      console.log("entra");
      formData.append("logo", lg[index]);
      console.log(lg[index], "nuevos");
      index++;
    }
    //console.log(clients)
    //console.log(logos)

    //Si el form está correctamente lleno manda la petición
    if (
      title.value &&
      description.value &&
      sectors.length != 0 &&
      tecnologias.length != 0 &&
      clients.length != 0
    ) {
      setIsLoading(true);
      const response = updateReports(formData);
      response
        .then((result) => {
          setIsLoading(false);
          localStorage.setItem("successEditedReport", "true");
          console.log("Valor de successEditedReport: ", localStorage.getItem("successEditedReport"));
          window.location.href = "/cid/analysis";

        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response.status === 500) {
            setOpen(true);
            setModalBody(
              <>
                <div className="d-flex justify-content-center">
                  <img
                    src={FailIcon}
                    alt="Fail Icon"
                    className="mb-3 modal__icon-modal"
                  />
                </div>
                <p className="mb-3 a-text-medium-second-neutral-90 text-center">
                  No se ha podido editar el análisis
                  <br /> Inténtelo de nuevo
                </p>
                <div className="d-flex justify-content-center">
                  <div className="w-50" onClick={handleClose}>
                    <Button
                      type="button"
                      classes="a-button-primary1"
                      height={36}
                      width="100%"
                      text="Aceptar"
                    />
                  </div>
                </div>
              </>
            );
          } else if (err.response.status === 400) {
            setOpen(true);
            setModalBody(
              <>
                <div className="d-flex justify-content-center">
                  <img
                    src={FailIcon}
                    alt="Fail Icon"
                    className="mb-3 modal__icon-modal"
                  />
                </div>
                <p className="mb-3 a-text-medium-second-neutral-90 text-center">
                  Datos incompletos
                </p>
                <div className="d-flex justify-content-center">
                  <div className="w-50" onClick={handleClose}>
                    <Button
                      type="button"
                      classes="a-button-primary1"
                      height={36}
                      width="100%"
                      text="Aceptar"
                    />
                  </div>
                </div>
              </>
            );
          }
        });
    }
  }

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={9} lg={12} xl={12} className="">
          <form onSubmitCapture={submitCreateForm}>
            <div className="d-flex justify-content-between">
              <p
                className={`${ipadMiniMatch ? "h3" : ""
                  } mb-3 mb-3 a-text-bold-second-neutral-70`}
              >
                Editar análisis
              </p>
              <div>
                <IconButton>
                  <img
                    src={Close}
                    alt="Close"
                    className="nav-company-icon"
                    onClick={handleBack}
                  />
                </IconButton>
              </div>
            </div>

            {
              !skeleton ?
                <>
                  <p className="mb-3 a-text-regular-second-neutral-40 text-small">
                    * Campo obligatorio
                  </p>

                  <div className="mb-3">
                    <InputWithLabel
                      text="Añadir título del análisis *"
                      id="report-title"
                      placeholder="Ingresa el título del análisis"
                      type="text"
                      width={80}
                      focus={true}
                      Location={true}
                      className=""
                      onChange={handleInputChange}
                      value={report.title}
                      warning={warningTitle}
                    />
                  </div>
                  <div className="mb-3">
                    <InputWithLabel
                      text="Descripción *"
                      id="report-description"
                      width={80}
                      placeholder="Ingresa una descripción del análisis"
                      type="textarea"
                      focus={true}
                      Location={true}
                      onChange={handleInputChange}
                      value={report.description}
                      warning={warningDescription}
                    />
                  </div>
                  <div className="mb-3 ">
                    <InputWithLabel
                      text="Documento *"
                      id="report-document"
                      type="document"
                      focus={true}
                      Location={true}
                      className=""
                      onChange={uploadFile}
                      value={file}
                    />
                    <span className="d-flex a-text-regular-first-neutral-100 text-small mt-3">
                      {nameFile}
                    </span>
                  </div>
                  <div className="mb-0">
                    <InputWithLabel
                      Location="true"
                      text="Clientes *"
                      id="report-client"
                      focus={true}
                      type="checkboxImg"
                      data={checkboxClient}
                      placeholder="Agregar cliente"
                      value={checkClientUpdate}
                      className="btn-checkbox-img-add"
                      limit={5}
                      seeMoreButton={true}
                      warning={warningClient}
                    />
                  </div>
                  {handleButton("client")}

                  <div className="mb-3 w-80">
                    {console.log('checkSectorUpdate', checkSectorUpdate)}
                    <InputWithLabel
                      text="Sectores"
                      id="report-sectores"
                      type="checkbox-sm"
                      limit={8}
                      seeMoreButton={true}
                      data={checkboxSector}
                      warning={warningSector}
                      onChange={(e, sectorSeleccionado) => {
                        if (e.target.checked) {
                          setSectorTemporal({
                            sectores: [
                              ...sectorTemporal.sectores,
                              sectorSeleccionado,
                            ],
                          });
                        } else {
                          setSectorTemporal({
                            sectores: sectorTemporal.sectores.filter(
                              (mer) => mer.name !== sectorSeleccionado.name
                            ),
                          });
                        }
                      }}
                      value={checkSectorUpdate}
                    />
                    {/* <AddButton
                      width={100}
                      widthBtn="50%"
                      id="report-sector"
                      array={checkboxSector}
                      placeholder="Ingresa el nombre de un sector"
                      type="checkbox"
                      add={(arr) => setCheckboxSector(arr)}
                      remove={(arr) => setCheckboxSector(arr)}
                      text="Agregar sector"
                      err=""
                      opcion="checkbox"
                      warning={warningSector}
                      // value={checkSectorUpdate}
                    /> */}
                  </div>
                  {/* {handleButton("sector")} */}

                  <div className="mb-3 w-80">
                    {console.log('checkMarketUpdate', checkMarketUpdate)}
                    <InputWithLabel
                      text="Tecnologías"
                      id="report-tecnologías"
                      type="checkbox-sm"
                      limit={8}
                      seeMoreButton={true}
                      data={checkboxMarket}
                      warning={warningMarket}
                      onChange={(e, marketSeleccionado) => {
                        if (e.target.checked) {
                          setMarketTemporal({
                            markets: [
                              ...marketTemporal.markets,
                              marketSeleccionado,
                            ],
                          });
                        } else {
                          setMarketTemporal({
                            markets: marketTemporal.markets.filter(
                              (mer) => mer.name !== marketSeleccionado.name
                            ),
                          });
                        }
                      }}
                      value={checkMarketUpdate}
                    />
                    {/* <AddButton
                      width={100}
                      widthBtn="50%"
                      id="report-market"
                      array={checkboxMarket}
                      placeholder="Ingresa el nombre de una tecnología"
                      type="checkbox"
                      add={(arr) => setCheckboxMarket(arr)}
                      remove={(arr) => setCheckboxMarket(arr)}
                      text="Agregar Tecnología"
                      err=""
                      opcion="checkbox"
                      warning={warningMarket}
                      // value={checkMarketUpdate}
                    /> */}
                  </div>
                  {/* {handleButton("market")} */}

                  <div className="mb-3 mt-5 w-80">
                    <Button
                      type="submit"
                      classes={`a-button-primary1 text-small ${isLoading ? "disabled" : ""
                        }`}
                      height={40}
                      width={"100%"}
                      text={isLoading ? <CircularProgress /> : "Guardar análisis"}
                    />
                  </div>
                </>
                :
                <>
                  <Error500connection noMostrarBoton={true} />
                </>

            }
          </form>
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">{modalBody}</div>
      </Modal>

    </>
  );
}

export { FormUpdateReport };
