import { useEffect, useState } from "react";
import { Select2Scroll } from "../../../atoms/Input/Select2Scroll.js";
import { Label } from "./Label.js";
import { requestOptions, locationDropdown } from "./index.js";

export function RegisterNewSedeComponent({ setSede, setValidSede, warning }) {
  const [pais, setPais] = useState("");
  const [estado, setEstado] = useState("");
  const [ciudad, setCiudad] = useState("");

  // API data
  const [paises, setPaises] = useState([]);
  const [estados, setEstados] = useState([]);
  const [ciudades, setCiudades] = useState([]);

  const [paisId, setPaisId] = useState("");
  const [estadoId, setEstadoId] = useState("");
  const [ciudadId, setCiudadId] = useState("");

  const [showEstados, setShowEstados] = useState(false);
  const [showCiudades, setShowCiudades] = useState(false);

  useEffect(() => {
    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPaises(result);
      });

  }, []);

  useEffect(() => {
    setCiudad("");
    setCiudadId("");
    setEstado("");
    setEstadoId("");

    setSede(pais);
  }, [pais]);

  useEffect(() => {
    setCiudad("");
    setCiudadId("");
    if (estado == "") return;
    setSede(estado + ", " + pais);

  }, [estado]);

  useEffect(() => {
    if (ciudad == "") return;
    setSede(ciudad + ", " + estado + ", " + pais);
  }, [ciudad]);


  useEffect(() => {
    // check validity
    let valid = true;

    if (paisId === "") valid = false;
    if (estadoId === "" && estado !== "") valid = false;
    if (ciudadId === "" && ciudad !== "") valid = false;
    setValidSede(valid);
  }, [paisId, estadoId, ciudadId]);

  useEffect(() => {
    if (paisId === "") return;
    setShowEstados(true);
    setShowCiudades(false);
    fetch(`https://api.countrystatecity.in/v1/countries/${paisId}/states`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setEstados(result);
      });
  }, [paisId]);

  useEffect(() => {
    setCiudad("");
    setCiudadId("");

    if (estadoId === "") return;

    setShowCiudades(true);
    fetch(`https://api.countrystatecity.in/v1/countries/${paisId}/states/${estadoId}/cities`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCiudades(result);
      });
  }, [estadoId]);
  return (
    <>
      <Label text="País *" />
      <Select2Scroll
        id="company-sede"
        placeholder="Selecciona el país"
        data={paises}
        onChange={(e) => {
          setPais(e.target.value);
          setPaisId("");
        }}
        onMouseDown={(item) => {
          setPais(item.name);
          setPaisId(item.iso2);
        }}
        nameKey={(elem) => elem.name}
        setData={setPais}
        value={pais}
        Children={locationDropdown}
        // onClickEvent={(item) => setPais(item.name)}
        width={"40%"} />
      {showEstados ? (
        <>
          <Label text="Estado" />
          <Select2Scroll
            id="company-sede"
            placeholder="Selecciona el estado"
            data={estados}
            onChange={(e) => {
              setEstado(e.target.value);
              setEstadoId("");
            }}
            onMouseDown={(item) => {
              setEstado(item.name);
              setEstadoId(item.iso2);
            }}
            nameKey={(elem) => elem.name}
            setData={setEstado}
            value={estado}
            Children={locationDropdown}
            onClickEvent={(item) => setEstado(item.name)}
            width={"40%"} />
        </>) : ""}

      {showCiudades ? (
        <>
          <Label text="Ciudad" />
          <Select2Scroll
            id="company-sede"
            placeholder="Selecciona la ciudad"
            data={ciudades}
            onChange={(e) => {
              setCiudadId("");
              setCiudad(e.target.value);
            }}
            onMouseDown={(item) => {
              setCiudad(item.name);
              setCiudadId(item.iso2);
            }}
            setData={setCiudad}
            value={ciudad}
            Children={locationDropdown}
            onClickEvent={(item) => setCiudad(item.name)}
            width={"40%"} />
        </>) : ""}
      {warning ? <p
        className="mt-2 a-text-regular-alert-60"
        style={{
          width: `calc(${100}% - 10px)`,
          height: 20,
          
        }}>
        {warning}
      </p> : ""}
    </>

  );
}
