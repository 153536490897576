import React, { useState } from 'react';
import './ExpandableCategoryCard.scss';
// Material-UI
import { Collapse, Grid, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Componentes
import { ExpandedCard } from '../../molecules/ExpandedCard/ExpandedCard';
import { FileResourceCard } from '../../molecules/FileResourceCard/FileResourceCard';

export const ExpandableCategoryCard = ({ icon, title, results, resources }) => {
    console.log("🔍 Props recibidas en ExpandableCategoryCard:", { icon, title, results, resources });

    const [isExpanded, setIsExpanded] = useState(false);
    const [activeCardId, setActiveCardId] = useState(null);

    const toggleExpand = () => {
        if (results > 0) {
            setIsExpanded(!isExpanded);
        }
    };

    const handleToggleShareCard = (id) => {
        setActiveCardId((prev) => (prev === id ? null : id));
    };

    const isDisabled = results === 0;

    return (
        <div
            style={{
                borderRadius: '12px',
                overflow: 'hidden',
                marginBottom: '16px',
                backgroundColor: isDisabled ? '#ecedec' : '#dceff3',
                boxShadow: '0 2px 10px 4px rgba(0, 0, 0, 0.06)',

            }}
        >
            <div
                onClick={toggleExpand}
                className="expandable-container"
                style={{
                    height: '38px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '6px 16px',
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    flexWrap: 'wrap',
                }}
            >
                <div
                    className="expandable-title"  // Asegúrate de que esta clase esté aplicada
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        overflow: 'hidden',
                        flex: '1',
                    }}
                >
                    <div style={{flexShrink: 0, backgroundColor: isDisabled ? '#ecedec' : '#dceff3'}}>{icon}</div>
                    <h2 className={`expandable-title ${isDisabled ? 'disabled' : ''}`}>
                        {title}
                        <span className="hide-text-mobile">
                            <span className="separador"> - </span>
                            <span
                                className="expandable-results"
                                style={{
                                    color: isDisabled ? '#9e9e9e' : '#116b9b',
                                    backgroundColor: isDisabled ? '#ecedec' : '#dceff3',
                                    alignItems: 'center',
                                    borderRadius: '3px',
                                }}
                            >
                            {results} resultados
                        </span>
                </span>
                    </h2>
                </div>
                {results > 0 && (
                    <div className="expandable-container">
                        <h2 className={`expandable-number ${isDisabled ? 'disabled' : ''}`}>
                            {results}
                        </h2>

                        <IconButton size="small" disabled={isDisabled}>
                            <ExpandMoreIcon
                                style={{
                                    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s ease-in-out',
                                    color: isDisabled ? '#9e9e9e' : '#0056b3',
                                }}
                            />
                        </IconButton>
                    </div>
                )}

            </div>

            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        {resources.some((resource) => resource.type_id === 2) && (
            <div
                style={{
                    padding: '16px',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '0 0 8px 8px',
                }}
            >
                <Grid container spacing={2}>
                    {resources
                        .filter((resource) => resource.type_id === 2)
                        .map((resource) => {
                            return (
                                <Grid item xs={12} key={resource.id}>
                                    <FileResourceCard
                                        id={resource.id}
                                        name={resource.name}
                                        url={resource.url}
                                        date={resource.created_at}
                                        size={resource.size}
                                        description={resource.description}
                                        isActive={activeCardId === resource.id}
                                        onToggleShare={() => handleToggleShareCard(resource.id)}
                                    />
                                </Grid>
                            );
                        })}
                </Grid>
            </div>
        )}

        {resources.some((resource) => resource.type_id === 1) && (
            <div
                style={{
                    padding: '16px',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '0 0 8px 8px',
                }}
            >
                <Grid container>
                    {resources
                        .filter((resource) => resource.type_id === 1)
                        .map((resource) => {
                                    return (
                                        <div className="d-flex flex-wrap px-2 py-2 bg-white rounded-bottom"
                                             key={resource.id}> {/* Usamos el ID único como key */}
                                            <ExpandedCard
                                                id={resource.id}
                                                icon={resource.logo_url}
                                                title={resource.name}
                                                url={resource.url}
                                                description={resource.description}
                                            />
                                        </div>
                                    );
                                })}
                        </Grid>
                    </div>
                )}
            </Collapse>
        </div>
    );
};
