import React, { useState, useEffect } from 'react';
import { createResource } from "../../../pages/CID/Resources/ResourcesHelpers/ResourcesHelpers";
import { InputWithLabel } from '../../molecules/InputWith/InputWithLabel';
import { Button } from '../../atoms/Button/';
import { CircularProgress, Grid, IconButton } from '@material-ui/core';
import Header from '../../molecules/Header';
import { Label } from "../Form/FormCompany/Label";
import { AutoexpansibleInput } from "../../atoms/Input/AutoexpansibleInput";
import { SelectScroll } from '../../atoms/Input/SelectScroll.js';
import { useAuth } from "../../../hooks/useAuth";
import Close from '../../../assets/img/icon/close.svg';
import './_index.scss';


export default function FormAddResources({ isCollapsed, resourceData }) {
    const [isLoading, setIsLoading] = useState(false);
    const [classificationValue, setClassificationValue] = useState(resourceData?.classification || "");
    const [typeValue, setTypeValue] = useState(resourceData?.type || "");
    const [description, setDescription] = useState(resourceData?.description || "");
    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);

    const [errors, setErrors] = useState({
        name: '',
        classification: '',
        type: '',
        description: '',
        file: '',
    });

    const classificationOptions = [
        { name: 'Sitios de consultoría', classification_id: 1 },
        { name: 'IA Generativa', classification_id: 2 },
        { name: 'Influencers', classification_id: 3 },
        { name: 'Redes sociales', classification_id: 4 },
        { name: 'Diseño de producto', classification_id: 5 },
        { name: 'Adicionales', classification_id: 6 },
    ];

    const typeOptions = [
        { name: 'Enlace Web (Link)', rolId: 1 },
        { name: 'Archivo', rolId: 2 },
    ];

    const filteredClassificationOptions = typeValue === "1"
        ? classificationOptions.filter(option => option.classification_id !== 6)
        : typeValue === "2"
            ? classificationOptions.filter(option => option.classification_id === 6)
            : classificationOptions;

    useEffect(() => {
        if (resourceData) {
            setClassificationValue(resourceData.classification);
            setTypeValue(resourceData.type);
            setDescription(resourceData.description);
        }
    }, [resourceData]);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                setFilePreview(fileReader.result);
            };

            if (selectedFile.type === "application/pdf") {
                fileReader.readAsDataURL(selectedFile);
            } else if (selectedFile.type.startsWith("image")) {
                fileReader.readAsDataURL(selectedFile);
            }
        }
    };

    const user = useAuth();
    const user_id = user?.userId;

    function submitCreateForm(e) {
        e.preventDefault();
        setIsLoading(true);

        const name = document.getElementById('add-resource-name')?.value || "";
        const url = document.getElementById('add-resource-url')?.value || "";

        let formErrors = {};
        if (!name) formErrors.name = "Este campo es obligatorio";
        if (!classificationValue) formErrors.classification = "Este campo es obligatorio";
        if (!typeValue) formErrors.type = "Este campo es obligatorio";
        if (!description) formErrors.description = "Este campo es obligatorio";

        setErrors(formErrors);

        if (Object.keys(formErrors).length > 0) {
            setIsLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append('name', name);
        formData.append('classification_id', classificationValue);
        formData.append('type_id', typeValue);
        formData.append('description', description);
        formData.append('user_id', user_id);

        if (Number(typeValue) === 1) {
            if (file) {
                formData.append('file_logo_url', file);
            }
            if (url) {
                formData.append('url', url);
            }
        } else {
            if (file) {
                formData.append('file_url', file);
            }
        }

        createResource(formData)
            .then(response => {
                console.log("Recurso creado correctamente:", response);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error al crear el recurso:", error);
                setIsLoading(false);
            });
    }

    return (
        <Grid container alignItems='center' spacing={0}>
            <Header hideMessageAndNoti={true} text='Buscar recurso' isCollapsed={isCollapsed} />
            <Grid item xs={12} alignItems='center'>
                <div className='background-lg-first-neutral-10 br-2 h-100vh-header scroll-overflow pt-4 pt-lg-0'>
                    <div className='background-lg-white m-lg-4 p-lg-4 br-2 box-shadow-lg-all'>
                        <div className='d-flex justify-content-between'>
                            <p className='ml-3 h3-lg text-big a-text-bold-second-neutral-70 align-self-center'>
                                Formulario de recursos
                            </p>
                            <IconButton>
                                <img
                                    src={Close}
                                    alt='Close'
                                    className='nav-company-icon'
                                    onClick={() => window.location.href = '/cid/resources'}
                                />
                            </IconButton>
                        </div>
                        <form onSubmit={submitCreateForm} className='ml-3 w-90 w-lg-60'>
                            <p className='a-text-regular-first-neutral-100 text-small mb-3'>* Campos obligatorios</p>

                            <div className='mb-6'>
                                <Label text='Nombre *'/>
                                <InputWithLabel
                                    classLabel='mb-2'
                                    id='add-resource-name'
                                    placeholder='Nombre(s)'
                                    type='text'
                                    defaultValue={resourceData?.name || ""}
                                />
                                {errors.name && <p className="text-error">{errors.name}</p>}
                            </div>

                            <div>
                                <Label text='Tipo *'/>
                                <SelectScroll
                                    data={typeOptions}
                                    nameKey={(elem) => elem.name}
                                    IdKey={(elem) => elem.rolId}
                                    id="add-resource-type"
                                    placeholder="Selecciona un tipo"
                                    dateChanged={(e) => setTypeValue(e.target.value)}
                                    value={typeValue}
                                    selectClass="position-absolute-list"
                                />
                                {errors.type && <p className="text-error">{errors.type}</p>}
                            </div>

                            <div>
                                <Label text='Clasificación *'/>
                                <SelectScroll
                                    data={filteredClassificationOptions}
                                    nameKey={(elem) => elem.name}
                                    IdKey={(elem) => elem.classification_id}
                                    id="add-resource-classification"
                                    placeholder="Selecciona una clasificación"
                                    dateChanged={(e) => setClassificationValue(e.target.value)}
                                    value={classificationValue}
                                    selectClass="position-absolute-list"
                                    disabled={!typeValue}
                                />
                                {errors.classification && <p className="text-error">{errors.classification}</p>}
                            </div>

                            {typeValue === "1" && (
                                <>
                                    <div className='mb-6'>
                                        <Label text='Ingresa el enlace web del sitio *'/>
                                        <InputWithLabel
                                            classLabel='mb-2'
                                            id='add-resource-url'
                                            placeholder='https://www.example.com/'
                                            type='text'
                                        />
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor="logo" className="mb-2">Logo *</label>
                                        <input
                                            id="add-resource-logo"
                                            type="file"
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            className="form-control"
                                        />
                                    </div>
                                    {filePreview && <img src={filePreview} alt="Vista previa" style={{ width: '250px', height: 'auto' }} />}
                                </>
                            )}

                            {typeValue === "2" && (
                                <div className="mb-6">
                                    <label htmlFor="archivo" className="mb-2">Archivo *</label>
                                    <input
                                        id='add-resource-url'
                                        type="file"
                                        accept=".pdf,.doc,.docx,.xls,.xlsx"
                                        onChange={handleFileChange}
                                        className="form-control"
                                    />
                                </div>
                            )}

                            {filePreview && !filePreview.startsWith("data:image") && (
                                <div className="file-preview">
                                    <p>Archivo seleccionado: {file.name}</p>
                                    {file.type === "application/pdf" && <embed src={filePreview} type="application/pdf" width="100%" height="500px" />}
                                </div>
                            )}

                            <div className='mb-6'>
                                <Label text='Descripción *'/>
                                <AutoexpansibleInput
                                    placeholder='Ingresa la descripción del recurso '
                                    setText={setDescription}
                                    text={description}
                                />
                                {errors.description && <p className="text-error">{errors.description}</p>}
                            </div>

                            <Button
                                type='submit'
                                classes='a-button-primary1'
                                height={36}
                                width='100%'
                                text={isLoading ? <CircularProgress /> : 'Añadir recurso'}
                            />
                        </form>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}