// React
import React, {useState} from 'react';

import styles from './_index.scss';
import ShareCard from "../ShareCard/ShareCard";
import ResourceInfoCard from "../ResourceInfoCard";

export const ExpandedCard = ({ icon, title, url, description, id, onClick }) => {
    const [mostrarShareCard, setMostrarShareCard] = useState(false);


    const toggleShareCard = () => {
        setMostrarShareCard((prev) => !prev);
    };

    const closeShareCard = () => {
        setMostrarShareCard(false);
    };
    return (
        <div
            onClick={toggleShareCard}
            className="d-flex flex-column align-items-center justify-content-center background-white rounded-pill cursor-pointer text-center"
             style={{height: "140px", width:"120px", borderRadius: "16px" }}>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ width: "100%", height: "110px" }}
            >
                {icon? (
                    <img
                        src={icon}
                        className="w-83 h-auto"
                        style={{ maxHeight: "100%", objectFit: "contain", borderRadius: "16px" }}
                        alt={title}
                    />
                ) : (
                    <span>Imagen no disponible</span>
                )}
            </div>

            <div className={styles['title']}>
                <span>{title}</span>
            </div>

            {mostrarShareCard && (
                <ResourceInfoCard id={id} name={title} logo={icon} url={url} description={description} onClose={closeShareCard}/>
            )}
        </div>
    );
};


