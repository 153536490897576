// React
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Material
import {
  useMediaQuery,
  Modal,
  Grid,
  createMuiTheme,
  withStyles,
  IconButton,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";

// Componentes
import { InputWithLabel } from "../../molecules/InputWith/InputWithLabel";
import { Button } from "../../atoms/Button/";
// Images

import FailIcon from "../../../assets/img/fail-icon.svg";
import Close from "../../../assets/img/icon/close.svg";

// Api
import {
  getSectorsReport,
  getMarketsReport,
  createReport,
  getClients,
} from "../../../api/report";

//Hook
import useFormAddReport from "../../../hooks/useFormAddReport";
import useFormAddReportErrors from "../../../hooks/useFormAddReportErrors";
import { Error500connection } from "../Error/500";

function FormAddReport({ userId }) {
  // State

  const {
    isLoading,
    checkboxSector,
    checkboxClient,
    checkboxMarket,
    buttonSector,
    buttonMarket,
    buttonClient,
    open,
    modalBody,
    file,
    logo,
    images,
    imagesNew,
    imagesNewEnd,
    temporalImage,
    nameFile,
    logoName,
    progress,
    setIsLoading,
    setCheckboxSector,
    setCheckboxClient,
    setCheckboxMarket,
    setButtonSector,
    setButtonClient,
    setButtonMarket,
    setOpen,
    setModalBody,
    setFile,
    setLogo,
    setImages,
    setImagesNew,
    setImagesNewEnd,
    setTemporalImages,
    setNameFile,
    setLogoName,
    setProgress,
  } = useFormAddReport({
    initialLoading: false,
    initialCheckboxSector: [],
    initialCheckboxClient: [],
    initialCheckboxMarket: [],
    initialButtonSector: false,
    initialButtonClient: false,
    initialButtonMarket: false,
    initialOpenState: false,
    initialModalBody: "",
    initialFile: [],
    initialLogo: [],
    initialImages: [],
    initialImagesNew: [],
    initialImagesend: [],
    initialtemporalImage: [],
    initialNameFile: "",
    initialLogoName: "",
    initialProgress: 10,
  });
  // Status err

  const {
    warningTitle,
    warningDescription,
    warningFile,
    warningClient,
    warningSector,
    warningMarket,
    warningImagen,
    setWarningTitle,
    setWarningDescription,
    setWarningFile,
    setWarningClient,
    setWarningSector,
    setWarningMarket,
    setWarningImagen,
  } = useFormAddReportErrors({
    initialwarningTitle: "",
    initialwarningDescription: "",
    initialwarningFile: "",
    initialwarningClient: "",
    initialwarningSector: "",
    initialwarningMarket: "",
    initialwarningImagen: "",
  });

  const [sectorTemporal, setSectorTemporal] = useState({sectores: []})
  const [marketTemporal, setMarketTemporal] = useState({markets: []})
  const [skeleton, setSkeleton] = useState(false);

  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  //const theme = useTheme();
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        md: 768,
        lg: 992,
      },
    },
  });
  //Different screens
  const iPadMatch = useMediaQuery(theme.breakpoints.between(768, 992));
  const iPadProMatch = useMediaQuery(theme.breakpoints.up("lg"));
  //Different screens

  //Material components with overwritten styles
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: "100%",
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#23a2e7",
    },
  }))(LinearProgress);
  //Material components with overwritten styles

  // Peticiones
  useEffect(() => {
    const sectors = getSectorsReport(userId);
    sectors.then((result) => {
      if (result.status === 200) {
        setCheckboxSector(result.data.sectors);
      } 
    }).catch(err => {
      console.log(err)
      setSkeleton(true);
    });
    const clients = getClients(userId);
    clients.then((result) => {
      if (result.status === 200) {
        //console.log(result.data.clientes)
        setCheckboxClient(result.data.clientes);
      }
    }).catch(err => {
      console.log(err)
      setSkeleton(true)
    });
    const markets = getMarketsReport(userId);
    markets.then((result) => {
      if (result.status === 200) {
        setCheckboxMarket(result.data.markets);
      }
    }).catch(err => {
      console.log(err)
      setSkeleton(true)
    });
  }, []);
  // Funciones

  console.log(skeleton)
  function addSector() {
    setButtonSector(!buttonSector);
    const sector = document.getElementById("sector").value;
    if (sector != "") {
      setCheckboxSector([
        ...checkboxSector,
        { name: sector, sector_id: checkboxSector.length + 1 },
      ]);
    }
  }
  function addClient() {
    setButtonClient(!buttonClient);
    const client = document.getElementById("client").value;
    const logoClient = images[0];
    const clientlogo =
      document.getElementById("report-client-img").files[0] || "";
    console.log(clientlogo, "file client");
    setImagesNew([...imagesNew, { clientlogo }]);
    const activ = 1;

    //const logoFile = undefined

    if (client != "") {
      setCheckboxClient([
        ...checkboxClient,
        {
          client_id: checkboxClient.length + 1,
          name: client,
          logo_client: logoClient,
          act: activ,
        },
      ]);
    }

    console.log(client, "client");
    const arrImages = [...images];
    console.log(images, "images");
    arrImages.splice(0, 1, "");
    console.log(arrImages, "arrImages");
    setImages(arrImages);
  }
  //console.log(imagesNew, "IMAGESNEW")
  function addMarket() {
    setButtonMarket(!buttonMarket);
    const market = document.getElementById("market").value;
    if (market != "") {
      setCheckboxMarket([
        ...checkboxMarket,
        { name: market, market_id: checkboxMarket.length + 1 },
      ]);
    }
  }
  function uploadFile(e) {
    var fileInput = document.getElementById("report-document");
    var filePath = fileInput.value;
    var allowedExtension = /(.pdf)$/i;
    if (!allowedExtension.exec(filePath)) {
      setOpen(true);
      setModalBody(
        <>
          <div className="d-flex justify-content-center">
            <img
              src={FailIcon}
              alt="Fail Icon"
              className="mb-3 modal__icon-modal"
            />
          </div>
          <p className="mb-3 a-text-medium-second-neutral-90 text-center">
            Intenta con un archivo con extensión .pdf
          </p>
          <div className="d-flex justify-content-center">
            <div className="w-50" onClick={handleClose}>
              <Button
                type="button"
                classes="a-button-primary1"
                height={36}
                width="100%"
                text="Aceptar"
              />
            </div>
          </div>
        </>
      );
      fileInput.value = "";
      return false;
    } else {
      //Image preview
      if (fileInput.files && fileInput.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          setNameFile(fileInput.files[0].name);
        };
        reader.readAsDataURL(fileInput.files[0]);
        console.log(fileInput.files[0]);
        setFile(reader.result);
      }
    }
  }
  function uploadImg(e, i) {
    var fileInput = document.getElementById("report-client-img");
    var filePath = fileInput.value;
    var allowedExtension = /(.jpeg|.png|jpg|.gif)$/i;
    if (!allowedExtension.exec(filePath)) {
      setOpen(true);
      setModalBody(
        <>
          <div className="d-flex justify-content-center">
            <img
              src={FailIcon}
              alt="Fail Icon"
              className="mb-3 modal__icon-modal"
            />
          </div>
          <p className="mb-3 a-text-medium-second-neutral-90 text-center">
            Intenta con un archivo con extensión .png, .jpg{" "}
          </p>
          <div className="d-flex justify-content-center">
            <div className="w-50" onClick={handleClose}>
              <Button
                type="button"
                classes="a-button-primary1"
                height={36}
                width="100%"
                text="Aceptar"
              />
            </div>
          </div>
        </>
      );
      fileInput.value = "";
      return false;
    } else {
      //Image preview
      if (fileInput.files && fileInput.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(fileInput.files[0]);
        reader.onload = function (e) {
          const arrImages = [...images];
          arrImages.splice(i, 1, reader.result);
          setImages(arrImages);
        };
        //console.log(reader.result)
        //setLogo(reader.result)
      }
    }
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAddClient = () => {
    setButtonClient(!buttonClient);
    if (images[0] != "") {
      const arrImages = [...images];
      arrImages.splice(0, 1, "");
      setImages(arrImages);
    }
  };
  const handleBack = () => {
    window.location.href = "/cid/analysis";
  };

  // Submit
  function submitCreateForm(e) {
    e.preventDefault();

    const user = userId;
    console.log(userId, "userid");
    const title = document.getElementById("report-title");
    const description = document.getElementById("report-description");
    const filepdf = document.getElementById("report-document");
    const sectorInputs = document.querySelectorAll(".report-sector:checked");
    const sectors = [];
    sectorInputs.forEach((sector) => {
      sectors.push(sector.value);
    });
    const marketInputs = document.querySelectorAll(".report-market:checked");
    const markets = [];
    marketInputs.forEach((market) => {
      markets.push(market.value);
    });
    const clientInputs = document.querySelectorAll(".report-client:checked");
    const clients = [];
    const logos = [];
    clientInputs.forEach((client) => {
      let clientData = JSON.parse(client.value);
      clients.push(clientData.name);
      logos.push(clientData.logo);
    });

    //Validaciones front

    if (!title.value) {
      setWarningTitle("Ingresa el título del análisis");
      title.classList.add("input-error");
    } else {
      setWarningTitle("");
      title.classList.remove("input-error");
    }

    if (!description.value) {
      setWarningDescription("Ingresa una descripción");
      description.classList.add("input-error");
    } else {
      setWarningDescription("");
      description.classList.remove("input-error");
    }

    if (filepdf.files.length == 0) {
      setWarningFile("Sube un documento para el reporte");
      document.getElementById("report-document").classList.add("input-error");
    } else {
      setWarningFile("");
    }

    let sectores = [];
    sectorTemporal.sectores.forEach(sector => sectores.push(sector.name))

    let tecnologias = [];
    marketTemporal.markets.forEach(market => tecnologias.push(market.name))

    if (sectorTemporal.length == 0) {
      setWarningSector("Selecciona al menos un sector");
    } else {
      setWarningSector("");
    }

    if (marketTemporal.length == 0) {
      setWarningMarket("Selecciona al menos una tecnología");
    } else {
      setWarningMarket("");
    }

    if (clients.length == 0) {
      setWarningClient("Selecciona al menos un cliente");
    } else {
      setWarningClient("");
    }

    //Validaciones front

    const lg = []; //para obtener los que ya estan registrados
    const wr = []; // para obtener los nuevos clientes

    logos.forEach((log) => {
      //llenar los arreglos y desechar los file que no funcionan
      if (log) {
        log.includes("data") ? wr.push(log) : lg.push(filepdf);
      } else {
        lg.push(null);
      }
    });
    imagesNew.forEach((logend) => {
      //unificar los que escogio de los actuales y los nuevos clientes que subio
      lg.push(logend.clientlogo);
    });
    //console.log(lg, "logos")

    const formData = new FormData();
    formData.append("userId", user);
    formData.append("title", title.value);
    formData.append("description", description.value);
    formData.append("file", filepdf.files[0]);
    formData.append("sector", sectores);
    formData.append("market", tecnologias);

    formData.append("clientes", clients);
    var index = 0;
    while (index < lg.length) {
      //mandar los clientes de acuerdo a su forma
      console.log("entra");
      formData.append("logo", lg[index]);
      console.log(lg[index], "nuevos");
      index++;
    }

    //Si el form está correctamente lleno manda la petición
    if (
      title.value &&
      description.value &&
      filepdf.files.length != 0 &&
      sectorTemporal.length != 0 &&
      marketTemporal.length != 0 &&
      clients.length != 0
    ) {
      setIsLoading(true);
      const timer = setInterval(() => {
        //setProgress((prevProgress) => (prevProgress >= 100 ? clearInterval(timer) : prevProgress + 10));
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(timer);
            return prevProgress;
          } else {
            return prevProgress + 10;
          }
        });
        console.log(progress);
      }, 2500);

      const response = createReport(formData);
      response
        .then((result) => {
          setIsLoading(false);
          setProgress(100);
          localStorage.setItem("successCreatedReport", "true");
          console.log("Valor de successCreatedReport: ", localStorage.getItem("successCreatedReport"));
          window.location.href = "/cid/analysis";
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response.status === 500) {
            setOpen(true);
            setModalBody(
              <>
                <div className="d-flex justify-content-center">
                  <img
                    src={FailIcon}
                    alt="Fail Icon"
                    className="mb-3 modal__icon-modal"
                  />
                </div>
                <p className="mb-3 a-text-medium-second-neutral-90 text-center">
                  No se ha podido registrar el análisis
                  <br /> Inténtelo de nuevo
                </p>
                <div className="d-flex justify-content-center">
                  <div className="w-50" onClick={handleClose}>
                    <Button
                      type="button"
                      classes="a-button-primary1"
                      height={36}
                      width="100%"
                      text="Aceptar"
                    />
                  </div>
                </div>
              </>
            );
          } else if (err.response.status === 400) {
            setOpen(true);
            setModalBody(
              <>
                <div className="d-flex justify-content-center">
                  <img
                    src={FailIcon}
                    alt="Fail Icon"
                    className="mb-3 modal__icon-modal"
                  />
                </div>
                <p className="mb-3 a-text-medium-second-neutral-90 text-center">
                  Datos incompletos
                </p>
                <div className="d-flex justify-content-center">
                  <div className="w-50" onClick={handleClose}>
                    <Button
                      type="button"
                      classes="a-button-primary1"
                      height={36}
                      width="100%"
                      text="Aceptar"
                    />
                  </div>
                </div>
              </>
            );
          }
        });
    }
  }

  function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={9} lg={12} xl={12} className="">
   
          <form onSubmitCapture={submitCreateForm}>
            <div className="d-flex justify-content-between">
              <p
                className={`${
                  ipadMiniMatch ? "h3" : ""
                } mb-3 a-text-bold-second-neutral-70`}
              >
                Formulario de análisis
              </p>
              <div>
                <IconButton>
                  <img
                    src={Close}
                    alt="Close"
                    className="nav-company-icon"
                    onClick={handleBack}
                  />
                </IconButton>
              </div>
            </div>

            {
              !skeleton ?
            <>
            <p className="mb-3 a-text-regular-second-neutral-40 text-small">
              * Campo obligatorio
            </p>
            <div className="mb-3">
              <InputWithLabel
                text="Añadir título del análisis *"
                id="report-title"
                width={80}
                placeholder="Ingresa el título del análisis"
                type="text"
                focus={true}
                warning={warningTitle}
              />
            </div>
            <div className="mb-3">
              <InputWithLabel
                text="Descripción *"
                id="report-description"
                width={80}
                placeholder="Ingresa una descripción"
                type="textarea"
                Location={true}
                warning={warningDescription}
              />
            </div>
            <div className="mb-4">
              <InputWithLabel
                text="Documento *"
                id="report-document"
                type="document"
                width={155}
                Location={true}
                className=""
                onChange={(e) => {
                  uploadFile(e, 0);
                }}
                warning={warningFile}
              />
              {nameFile && (
                <span className="d-flex a-text-regular-first-neutral-100 text-small mt-3">
                  {nameFile}
                </span>
              )}
            </div>
            <div className="mb-0">
              <InputWithLabel
                text="Clientes *"
                id="report-client"
                placeholder="Agregar cliente"
                type="checkboxImg"
                Location={true}
                data={checkboxClient}
                value={[]}
                className="btn-checkbox-img-add"
                limit={5}
                warning={warningClient}
              />
            </div>

            {buttonClient ? (
              <div className="mb-3">
                <InputWithLabel
                  text="Logo"
                  id="report-client-img"
                  type="logoFile"
                  width={155}
                  Location={true}
                  value={images[0]}
                  onChange={(e) => {
                    uploadImg(e, 0);
                  }}
                />
                <InputWithLabel
                  text="Nombre del cliente *"
                  id="client"
                  placeholder="Ingresa el nombre de un cliente"
                  type="add"
                  onClick={addClient}
                  classLabel="mt-3"
                />
              </div>
            ) : (
              ""
            )}
            <div className="mb-4" onClick={handleCloseAddClient}>
              {/*
              <Button
                classes="button-form text-small minuta_btn-add"
                height={iPadMatch ? 40 : 32}
                width={iPadProMatch ? 255 : "60%"}
                text={buttonClient ? "Cancelar" : "Agregar cliente"}
                type="button"
              />
              */}
            </div>
            <div className="mb-3 w-80">
              {/* <AddButton
                width={100}
                widthBtn="50%"
                id="report-sector"
                array={checkboxSector}
                placeholder="Ingresa el nombre de un sector"
                type="checkbox"
                add={(arr) => setCheckboxSector(arr)}
                remove={(arr) => setCheckboxSector(arr)}
                text="Agregar sector"
                err=""
                opcion="checkbox"
                warning={warningSector}
                value={[]}
              /> */}
              <InputWithLabel 
                text="Sectores"
                id="report-sectores"
                type="checkbox-sm"
                limit={8}
                seeMoreButton={true}
                data={checkboxSector}
                warning={warningSector}
                onChange={(e, sectorSeleccionado) => {
                    if (e.target.checked) {
                      // setMercadoTemporal([...mercadoTemporal, marketSeleccionado])
                      setSectorTemporal({
                        sectores: [
                          ...sectorTemporal.sectores,
                          sectorSeleccionado,
                        ],
                      });
                    } else {
                      setSectorTemporal({
                        sectores: sectorTemporal.sectores.filter(
                          (mer) => mer.name !== sectorSeleccionado.name
                        ),
                      });
                    }
                  }}
                value={sectorTemporal.sectores}
              />
            </div>
 
            <div className="mb-3 w-80">
              {/* <AddButton
                width={100}
                widthBtn="50%"
                id="report-market"
                array={checkboxMarket}
                placeholder="Ingresa el nombre de una tecnología"
                type="checkbox"
                add={(arr) => setCheckboxMarket(arr)}
                remove={(arr) => setCheckboxMarket(arr)}
                text="Agregar tecnología"
                err=""
                opcion="checkbox"
                warning={warningMarket}
                value={[]}
              /> */}

            <InputWithLabel 
                text="Tecnologías"
                id="report-tecnologías"
                type="checkbox-sm"
                limit={8}
                seeMoreButton={true}
                data={checkboxMarket}
                warning={warningMarket}
                onChange={(e, marketSeleccionado) => {
                    if (e.target.checked) {
                      // setMercadoTemporal([...mercadoTemporal, marketSeleccionado])
                      setMarketTemporal({
                        markets: [
                          ...marketTemporal.markets,
                          marketSeleccionado,
                        ],
                      });
                    } else {
                      setMarketTemporal({
                        markets: marketTemporal.markets.filter(
                          (mer) => mer.name !== marketSeleccionado.name
                        ),
                      });
                    }
                  }}
                value={marketTemporal.markets}
              />
            </div>

            {/* <Button type="submit" classes={`mb-5 a-button-primary1 text-small ${isLoading ? 'disabled' : ''}`} height={iPadMatch ? 40 : 32} width={iPadProMatch ? 500 : "100%"} text={isLoading ? <BorderLinearProgress variant="determinate" value={progress} /> : "Guardar análisis"} /> */}
            
        
            <div className="mb-3 mt-5 w-80">
              <Button
                type="submit"
                classes={`a-button-primary1 text-small ${
                  isLoading ? "disabled" : ""
                }`}
                height={40}
                width={"100%"}
                text={isLoading ? <CircularProgress /> : "Guardar análisis"}
              />
            </div>
            </>
            : 
            <>
              {/* <Skeleton 
                variant="rect"
                width={120}
                height={15}
                animation="wave"
                className="mb-3"
              />

              <div>
                <Skeleton 
                  variant="rect"
                  width={200}
                  height={20}
                  animation="wave"
                  className="mb-2"
                />
                <Skeleton 
                  variant="rect"
                  height={30}
                  animation="wave"
                  className="mb-3"
                />
              </div>

              <div>
                <Skeleton 
                  variant="rect"
                  width={200}
                  height={20}
                  animation="wave"
                  className="mb-2"
                />
                <Skeleton 
                  variant="rect"
                  height={30}
                  animation="wave"
                  className="mb-3"
                />
              </div>

              <div>
                <Skeleton 
                  variant="rect"
                  width={200}
                  height={20}
                  animation="wave"
                  className="mb-2"
                />
                <Skeleton 
                  variant="rect"
                  height={30}
                  width={250}
                  animation="wave"
                  className="mb-3"
                />
              </div>

              <div>
                <Skeleton 
                  variant="rect"
                  width={200}
                  height={20}
                  animation="wave"
                  className="mb-2"
                />
                <div className="d-flex mb-2">  
                  <Skeleton
                    variant="rect"
                    height={100}
                    width={100}
                    animation="wave"
                    className="mr-3"
                  />
                   <Skeleton
                    variant="rect"
                    height={100}
                    width={100}
                    animation="wave"
                    className="mr-3"
                  />
                   <Skeleton
                    variant="rect"
                    height={100}
                    width={100}
                    animation="wave"
                    className=" mr-3"
                  />
                   <Skeleton
                    variant="rect"
                    height={100}
                    width={100}
                    animation="wave"
                    className="mr-3"
                  />
                   <Skeleton
                    variant="rect"
                    height={100}
                    width={100}
                    animation="wave"
                    className="mr-3"
                  />
                </div>
                <Skeleton
                    variant="rect"
                    height={15}
                    width={80}
                    animation="wave"
                    className="mb-3 mr-3"
                  />
              </div>

          <Skeleton
            variant="rect"
            width={200}
            height={20}
            animation="wave"
            className="my-2"
          /> */}
          
          {/* Sectores */}
          {/* <div className="d-flex flex-wrap">
            <Skeleton
              variant="rect"
              width={100}
              height={30}
              animation="wave"
              className="br-3 my-1 mr-2"
            />
            <Skeleton
              variant="rect"
              width={100}
              height={30}
              animation="wave"
              className="br-3 my-1 mr-2"
            />
            <Skeleton
              variant="rect"
              width={100}
              height={30}
              animation="wave"
              className="br-3 my-1 mr-2"
            />
            <Skeleton
              variant="rect"
              width={100}
              height={30}
              animation="wave"
              className="br-3 my-1 mr-2"
            />
            <Skeleton
              variant="rect"
              width={100}
              height={30}
              animation="wave"
              className="br-3 my-1 mr-2"
            />
            <Skeleton
              variant="rect"
              width={100}
              height={30}
              animation="wave"
              className="br-3 my-1 mr-2"
            />
             <Skeleton
              variant="rect"
              width={100}
              height={30}
              animation="wave"
              className="br-3 my-1 mr-2"
            />
            <Skeleton
              variant="rect"
              width={100}
              height={30}
              animation="wave"
              className="br-3 mt-1 mb-2 mr-2"
            />
          </div>
          <Skeleton
            variant="rect"
            height={15}
            width={80}
            animation="wave"
            className="mb-3 mr-3"
            /> */}

          {/* Tecnologias */}
          {/* <Skeleton
            variant="rect"
            width={200}
            height={20}
            animation="wave"
            className="my-2"
          />
           */}
          {/* <div className="d-flex flex-wrap">
            <Skeleton
              variant="rect"
              width={100}
              height={30}
              animation="wave"
              className="br-3 my-1 mr-2"
            />
            <Skeleton
              variant="rect"
              width={100}
              height={30}
              animation="wave"
              className="br-3 my-1 mr-2"
            />
            <Skeleton
              variant="rect"
              width={100}
              height={30}
              animation="wave"
              className="br-3 my-1 mr-2"
            />
            <Skeleton
              variant="rect"
              width={100}
              height={30}
              animation="wave"
              className="br-3 my-1 mr-2"
            />
            <Skeleton
              variant="rect"
              width={100}
              height={30}
              animation="wave"
              className="br-3 my-1 mr-2"
            />
            <Skeleton
              variant="rect"
              width={100}
              height={30}
              animation="wave"
              className="br-3 my-1 mr-2"
            />
             <Skeleton
              variant="rect"
              width={100}
              height={30}
              animation="wave"
              className="br-3 my-1 mr-2"
            />
            <Skeleton
              variant="rect"
              width={100}
              height={30}
              animation="wave"
              className="br-3 mt-1 mb-2 mr-2"
            />
          </div>
          <Skeleton
            variant="rect"
            height={15}
            width={80}
            animation="wave"
            className="mb-3 mr-3"
            />

          <Skeleton
            variant="rect"
            height={40}
            animation="wave"
            className="mt-5 mb-3"
            /> */}
            {/* <div className="d-flex justify-content-around">
                <div className="d-flex flex-column justify-content-center">
                  <p className="text-biggest a-text-medium-first-primary-60">500</p>
                  <p className="a-text-medium-first-primary-60 h3 ">No eres tú, somos nosotros</p>
                  <p className="a-text-regular-first-primary-60 ">Tenemos un problema en el servidor</p>
                </div>
               <img src={Buho} className="w-40" />
            </div> */}
            <Error500connection noMostrarBoton={true}/>
            </>
} 
            

            {/* <Button type="submit" classes={`text-small a-button-blue-1 roboto-bold ${isLoading ? 'disabled' : ''}`} height={40} width="100%" text={isLoading ? <CircularProgress /> : "Agregar empresa"} /> */}
          </form>
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">{modalBody}</div>
      </Modal>
      
    </>
    
  );
}

export { FormAddReport };
