// React
import React, { useState } from 'react';
import './_index.scss';

// Componentes
import ShareCard from '../ShareCard/ShareCard';

// Imágenes
import PDFIcon from '../../../assets/img/icon/pdf.png';
import JPGIcon from '../../../assets/img/icon/jpg.png';
import DOCIcon from '../../../assets/img/icon/doc.png';
import XLSIcon from '../../../assets/img/icon/xls.png';
import FileDownload from '../../../assets/img/icon/file_download.svg';
import Share from '../../../assets/img/icon/compartir.svg';
import ResourceInfoCard from "../ResourceInfoCard";
import {Link} from "react-router-dom";
import {IconButton} from "@material-ui/core";
import PencilIconOcean from "../../../assets/img/pencil-icon-ocean.svg";
import TrashIconOcean from "../../../assets/img/trash-icon-ocean.svg";
import {deleteResourceById} from "../../../pages/CID/Resources/ResourcesHelpers/ResourcesHelpers";
import AlertIcon from "../../../assets/img/alert.svg";
import {Button} from "../../atoms/Button";
import Modal from "@material-ui/core/Modal";


const getExtension = (filename) => {
    const parts = filename.split(".");
    return parts[parts.length - 1].toLowerCase();
};

export const FileResourceCard = ({ name, date, size, url, id, description}) => {
    const fileExtension = getExtension(url);
    const formattedDate = new Date(date).toLocaleDateString("es-ES");
    const [mostrarShareCard, setMostrarShareCard] = useState(false);
    const [mostrarDescriptionCard, setMostrarDescriptionCard] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [modalBody, setModalBody] = useState('');

    const toggleShareCard = () => {
        setMostrarShareCard((prev) => !prev);
    };

    const closeShareCard = () => {
        setMostrarShareCard(false);
    };


    const toggleDescriptionCard = () => {
        setMostrarDescriptionCard((prev) => !prev);
    };

    const closeDescriptionCard = () => {
        setMostrarDescriptionCard(false);
    };


    const getIconByExtension = (ext) => {
        switch (ext.toLowerCase()) {
            case "pdf":
                return PDFIcon;
            case "doc":
            case "docx":
                return DOCIcon;
            case "jpg":
                return JPGIcon;
            case "xls":
                return XLSIcon;
            default:
                return PDFIcon;
        }
    };

    const img = getIconByExtension(fileExtension);

    function deleteResource(id) {
        deleteResourceById(id)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsOpen(false);
    }

    function handleDeleteButton(id) {
        setIsOpen(true);
        setModalBody(
            <>
                <div className='d-flex justify-content-center mb-4'>
                    <img
                        src={AlertIcon}
                        alt='Warning'
                    />
                </div>
                <p className='mb-4 a-text-medium-second-neutral-90 text-center'>
                    No se podrán deshacer los cambios.
                    <br />
                    ¿Seguro que deseas eliminar este recurso?
                </p>
                <div className='d-flex justify-content-center'>
                    <div
                        className='mr-3 w-100'
                        onClick={() => setIsOpen(false)}
                    >
                        <Button
                            type='button'
                            classes='a-button-primary1 text-small'
                            height={36}
                            width='100%'
                            text='Cancelar'
                        />
                    </div>
                    <div
                        onClick={() => deleteResource(id)}
                        className='w-100'
                    >
                        <Button
                            type='button'
                            classes='a-button-light text-small'
                            height={36}
                            width='100%'
                            text='Aceptar'
                        />
                    </div>
                </div>
            </>,
        );
    }

    return (
        <div className="d-flex align-items-center justify-content-between p-2 background-white"
             style={{borderRadius: "16px"}}>
            <button
                onClick={toggleDescriptionCard}
                className="d-flex align-items-center text-decoration-none p-2 background-white"
                style={{
                    border: "1px solid transparent",
                    textAlign: "left",
                    justifyContent: "flex-start",
                }}
            >
                <div className="d-flex align-items-center gap-3">
                    <img src={img} alt="extension" className="h-100" style={{maxHeight: "60px"}}/>
                    <div className="d-flex flex-column px-2">
                        <span style={{fontWeight: '600', fontSize: '16px', color: '#355471'}}>{name}</span>
                        <div className="text-xs d-flex align-items-center gap-2 pt-2">
                            {formattedDate}
                            <img src={FileDownload} alt="Download" className="h-10 w-10 ms-2"/>
                            {size}
                        </div>
                    </div>
                </div>
            </button>

            <div className="buttons-container">
                <Link to={`/cid/update-resource/${id}`} className="text-decoration-none w-100">
                    <IconButton justify="space-around">
                        <img src={PencilIconOcean} alt="Pencil Icon" width="20px"/>
                    </IconButton>
                </Link>

                <IconButton className="ml-3" onClick={() => handleDeleteButton(id)}>
                    <img src={TrashIconOcean} alt="Trash Icon" width="20px"/>
                </IconButton>
                <IconButton className="ml-3" onClick={toggleShareCard}>
                    <img src={Share} alt="Copy" width="20px"/>
                </IconButton>
        </div>
            {mostrarShareCard && (
                <ShareCard name={name} logo={img} url={url} onClose={closeShareCard}/>
            )}
            {mostrarDescriptionCard && (
                <ResourceInfoCard id={id} name={name} logo={img} url={url} description={description}
                                  onClose={closeDescriptionCard}/>
            )}
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
            >
                <div className='modal'>{modalBody}</div>
            </Modal>
</div>
)
    ;
};


