import {BASE_PATH} from "../../../../api/config";

export async function getResources(page = 1) {
    console.log("Valor de page en helper", page)
    try {
        const url = `${BASE_PATH}/get-resources?pagination=${page}`;

        const response = await fetch(url);

        if (!response.ok) {
            const errorMessage = `Error en la respuesta de la API: ${response.status} - ${response.statusText}`;
            console.error("getResources() - ERROR:", errorMessage);
            return [];
        }

        const data = await response.json();
        console.log("📦 API Response Data:", data); // Log de los datos obtenidos
        return data || [];
    } catch (error) {
        const errorMessage = `Error al obtener los recursos: ${error.message || error}`;
        console.error("getResources() - ERROR en catch:", errorMessage);
        return [];
    }
}

export const getResourceId = async (id) => {
    const url = `${BASE_PATH}/get-resource/${id}`;
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error("Error al obtener los datos del recurso");
        }
        const data = await response.json();
        return data.resource;
    } catch (err) {
        throw new Error(err.message);
    }
};


export async function createResource(formData) {
    try {
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.get('name'));
        formDataToSend.append('classification_id', formData.get('classification_id'));
        formDataToSend.append('type_id', formData.get('type_id'));
        formDataToSend.append('description', formData.get('description'));
        formDataToSend.append('user_id', formData.get('user_id'));

// Agregar solo si existen
        if (formData.get('file_url')) formDataToSend.append('file_url', formData.get('file_url'));
        if (formData.get('url')) formDataToSend.append('url', formData.get('url'));
        if (formData.get('file_logo_url')) formDataToSend.append('file_logo_url', formData.get('file_logo_url'));

        const response = await fetch(`${BASE_PATH}/create-resource`, {
            method: 'POST',
            body: formDataToSend,
        });


        if (!response.ok) {
            const errorMessage = `Error en la respuesta de la API: ${response.statusText}`;
            console.error(errorMessage);
            throw new Error('Error al crear el recurso');
        }
        localStorage.setItem("successCreated", "true");
        console.log("Valor de successCreated: ", localStorage.getItem("successCreated"));
        window.location.href = "/cid/resources"
        return await response.json();
    } catch (error) {
        const errorMessage = `Error creando el recurso: ${error.message || error}`;
        console.error(errorMessage);
        throw error;
    }
}

export const deleteResourceById = async (id) => {
    const url = `${BASE_PATH}/delete-resource/${id}`;
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error al eliminar el recurso: ${response.statusText}`);
        }
        localStorage.setItem("successDeleted", "true");
        console.log("Valor de successDeleted: ", localStorage.getItem("successDeleted"));
        window.location.href = "/cid/resources"
        return await response.json();
    } catch (error) {
        console.error('Error eliminando el recurso:', error);
        throw new Error('Error al eliminar el recurso');
    }
}

export const updateResource = async (formData) => {
    const url = `${BASE_PATH}/update-resource/`;
    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error("Error al actualizar el recurso");
        }

        return await response.json();
    } catch (error) {
        console.error("Error al actualizar el recurso:", error);
        throw error;
    }
};

