import React, { useState } from "react";
// Material
import {
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
} from "@material-ui/core";

import closeIcon from "../../../../assets/img/closeFilter.svg";
import OrderIconWhite from "../../../../assets/img/ordenar_blanco.svg";

const labels = [
  {
    name: "Más recientes",
    value: "rec",
  },
  {
    name: "Más antiguos",
    value: "ant",
  },
  {
    name: "De A a la Z",
    value: "az",
  },
  {
    name: "De Z a la A",
    value: "za",
  },
];


export function Order({
  onClick,
  action,
  orderType,
  setOrderType
}) {
  return (
    <>
    <div className="d-flex justify-content-between mt-3">
        <p className="a-text-regular-first-primary-60 align-self-center text-medium">
          Ordernar por:
        </p>
        <div className="mr-3">  
          <IconButton onClick={onClick}>
            <img
              src={closeIcon}
              className="filter-label-icon width-cerrar-icon"
              alt="Close"
            />
          </IconButton>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <div
          className={`d-flex align-items-center mr-lg-3 ${"background-first-primary-60 cursor-pointer"
          } py-2 px-2 br-1 mr-2`}
          onClick={onClick}
        >
          <img src={OrderIconWhite} alt="Order" className="img-order" />
          <p className="ml-2 text-small text-md-normal a-text-regular-third-neutral">
            Ordenar
          </p>
        </div>
      </div>
      <div className="">
        {labels.map(({ name, value }, index) => {
          return (
            <div className="mb-4 d-flex">
              <input
                onChange={() => {
                    setOrderType(value)
                }}
                checked={orderType == value}
                type="checkbox"
                id={name}
                className=" radioNews cursor-pointer check-blog-filter"
                name={name}
                value={value}
              />
              <label
                className="a-text-regular-first-primary-60 text-normal ml-3 cursor-pointer"
                htmlFor={name}
              >
                {name}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
}
